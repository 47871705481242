<script setup>
import { computed } from 'vue'
import { MapPinIcon } from '@heroicons/vue/24/outline'
import { place_moments_link } from '@/helpers/LinkHelpers'
const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
})

const tokenLink = computed(() => {
  return props.link ? props.link : place_moments_link(props.record.id)
})

const hover_classes_override = computed(() => {
  return props.mode == 'removable'
    ? 'hover:bg-red-300 dark:hover:bg-red-500 hover:text-red-600 dark:hover:text-red-900 hover:border-red-600 dark:hover:border-red-800'
    : 'dark:hover:bg-yellow-800/50 dark:hover:text-yellow-400'
})
</script>
<template>
  <!--  me-2 = margin-inline, or spacing between tags → https://tailwindcss.com/docs/margin -->
  <a
    :href="tokenLink"
    :title="'View all moments in: ' + props.record.name"
    :class="hover_classes_override"
    class="border border-2 me-2 mb-1 px-2.5 py-0.5 rounded-lg inline-block bg-orange-100 hover:bg-orange-200 dark:bg-gray-800 border-orange-400/50 font-medium text-xs text-orange-800 dark:text-orange-400 dark:hover:text-black cursor-pointer"
    data-test="place-token"
  >
    <MapPinIcon class="size-4 text-orange-400 inline m-1 ml-0" />
    <slot />
  </a>
</template>
