<script setup>
import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline'
import FieldLabel from '@/components/FieldLabel.vue'

const model = defineModel({
  required: true,
  type: String,
  default: 'bright',
  validator: (value) => ['bright', 'dark'].includes(value),
})
</script>
<template>
  <fieldset class="mb-5">
    <FieldLabel label-for="image" text="Media">
      <SunIcon class="size-4 text-gray inline mb-1" /> ·
      <MoonIcon class="size-4 text-gray inline mb-1" />
      Aspect
    </FieldLabel>

    <ul
      class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
    >
      <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="radio-aspect-bright"
            v-model="model"
            type="radio"
            name="aspect"
            value="bright"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="radio-aspect-bright"
            class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Bright Moment</label
          >
        </div>
      </li>
      <li class="w-full border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="radio-aspect-dark"
            v-model="model"
            type="radio"
            name="aspect"
            value="dark"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="radio-aspect-dark"
            class="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >After Dark</label
          >
        </div>
      </li>
    </ul>
  </fieldset>
</template>
