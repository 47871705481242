<script setup>
import { computed, onMounted, ref } from 'vue'
import { BellAlertIcon, ClockIcon } from '@heroicons/vue/24/outline'
import { MoonIcon } from '@heroicons/vue/24/solid'
import '@/assets/settings.css'
import { format_date } from '@/helpers/DateHelpers'

import BaseButton from '@/components/BaseButton.vue'
import HeaderEyebrow from '@/components/HeaderEyebrow.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import ReminderSettingsAfterDark from '@/components/ReminderSettingsAfterDark.vue'
import ToggleCheckbox from '@/components/ToggleCheckbox.vue'
import ReminderQuietPeriod from '@/components/ReminderQuietPeriod.vue'
import TransportSelector from '@/components/TransportSelector.vue'

import ApiService from '@/api'

const api = ApiService()
const loaded = ref(false)
const error = ref(false)
let rs = {}
const rs_enabled = ref(false)
const rs_reminder_interval_hours = ref(1)
const rs_last_reminder_sent = ref()
const rs_dark_moments_in_reminders = ref(undefined)
const rs_reminder_transport = ref()

// quiet period handling
async function save_quiet_settings(field, newValue) {
  var minimal_rs = {}
  minimal_rs[field] = newValue
  const response = await api.updateReminderSettings(minimal_rs)
  if (!response.ok) {
    error.value = true
    return
  }
  error.value = false
}

const respect_quiet_periods = ref(false)
const quiet_period_start = ref(17)
const quiet_period_end = ref(8)

const reminders_enabled = computed(() => {
  return rs_enabled.value
})

const rs_enabled_label = computed(() => {
  return rs_enabled.value ? 'Enabled' : 'Disabled'
})

const interval_control_disabled = computed(() => {
  return rs_enabled.value ? false : true
})

const interval_control_disabled_classes = computed(() => {
  return rs_enabled.value ? '' : 'text-zinc-300 dark:text-zinc-500'
})

const reminders_enabled_classes = computed(() => {
  return rs_enabled.value ? '' : 'opacity-20'
})

const last_reminder_sent_display = computed(() => {
  return rs.last_reminder_sent ? rs_last_reminder_sent.value : '...'
})

// FIXME this feels ugly
const sync_settings_and_update_form = async () => {
  rs.enabled = rs_enabled.value
  rs.reminder_interval_hours = rs_reminder_interval_hours.value
  rs.dark_moments_in_reminders = rs_dark_moments_in_reminders.value

  // only send permitted / necessary params:
  const minimal_rs = {
    enabled: rs.enabled,
    reminder_interval_hours: rs.reminder_interval_hours,
    dark_moments_in_reminders: rs.dark_moments_in_reminders,
  }
  const response = await api.updateReminderSettings(minimal_rs)
  if (!response.ok) {
    error.value = true
    return
  }
  error.value = false
}

const send_test_reminder = async () => {
  const response = await api.sendTestReminder()
  if (!response.ok) {
    error.value = true
    return
  }
  error.value = false
}

onMounted(async () => {
  const response = await api.getUser()
  if (!response.ok || !response.data?.user?.reminder_settings) {
    error.value = true
    loaded.value = true
    return
  }

  rs = response.data.user.reminder_settings
  console.debug('loaded ReminderSettings: ', rs)
  rs_enabled.value = rs.enabled
  rs_dark_moments_in_reminders.value = rs.dark_moments_in_reminders
  rs_reminder_transport.value = rs.reminder_transport_type

  if (rs.last_reminder_sent) {
    const db_date = new Date(rs.last_reminder_sent)
    rs_last_reminder_sent.value = format_date(db_date)
  }

  // quiet period handling
  respect_quiet_periods.value = !!rs.respect_quiet_periods
  quiet_period_start.value = rs.quiet_period_start || 17
  quiet_period_end.value = rs.quiet_period_end || 8

  error.value = false
  loaded.value = true
})
</script>
<template>
  <div data-test="reminder-settings">
    <HeadlineText headline="" sub-head="Configure Moment Reminders">
      <BellAlertIcon class="size-6 text-gray inline mb-1" />
    </HeadlineText>

    <div v-if="error" class="text-center my-8">
      <p class="text-red-500 mb-4">Failed to load or update settings :(</p>
      <BaseButton label="Retry" @click="$router.go()" />
    </div>
    <section v-else-if="loaded">
      <fieldset>
        <HeaderEyebrow>Receive Reminders</HeaderEyebrow>
        <ToggleCheckbox
          v-model="rs_enabled"
          :label="rs_enabled_label"
          @toggle="sync_settings_and_update_form"
        />

        <aside>
          <a href="/blog/help/reminder-setup" class="font-normal text-sm text-zinc-500 underline"
            >Learn more about how moments are resurfaced.</a
          >
        </aside>
      </fieldset>

      <!-- collapse when reminders disabled -->
      <div class="transition-all duration-800 ease-in-out" :class="reminders_enabled_classes">
        <!-- TRANSPORT SELECTAH -->
        <HeaderEyebrow> Rediscovery Method </HeaderEyebrow>

        <TransportSelector :enabled="reminders_enabled" />

        <fieldset>
          <HeaderEyebrow>
            <MoonIcon class="size-4 text-gray inline mb-1" /> Dark moments in Reminders?
          </HeaderEyebrow>
          <ReminderSettingsAfterDark
            v-model="rs_dark_moments_in_reminders"
            :enabled="reminders_enabled"
            @change="sync_settings_and_update_form"
          />
        </fieldset>

        <ReminderQuietPeriod
          :enabled="reminders_enabled"
          :respect-quiet-periods="respect_quiet_periods"
          :quiet-period-start="quiet_period_start"
          :quiet-period-end="quiet_period_end"
          @update:respect-quiet-periods="save_quiet_settings('respect_quiet_periods', $event)"
          @update:quiet-period-start="save_quiet_settings('quiet_period_start', $event)"
          @update:quiet-period-end="save_quiet_settings('quiet_period_end', $event)"
        />
        <fieldset>
          <HeaderEyebrow :class="interval_control_disabled_classes">
            Reminder Interval
          </HeaderEyebrow>
          <label :class="interval_control_disabled_classes">
            <input
              v-model="rs_reminder_interval_hours"
              type="number"
              :disabled="interval_control_disabled"
              min="1"
              max="3"
              step="1"
              class="my-2 mr-3 px-3 py-2 rounded-lg bg-gray-50 dark:bg-gray-700"
              :class="interval_control_disabled_classes"
              @change="sync_settings_and_update_form"
            />
            <ClockIcon class="size-6 text-gray inline mb-1" />
            Hours
          </label>
        </fieldset>
      </div>

      <fieldset>
        <HeaderEyebrow>Last Reminder Sent</HeaderEyebrow>
        {{ last_reminder_sent_display }}
      </fieldset>

      <fieldset>
        <label>
          <HeaderEyebrow>Test Reminder</HeaderEyebrow>
          <BaseButton
            data-test="test-reminder-button"
            label="Send Test Reminder"
            @click.once="send_test_reminder"
          >
            <BellAlertIcon class="size-6 text-gray inline mb-1 mr-1" />
          </BaseButton>
        </label>
      </fieldset>
    </section>
    <div v-else class="text-center my-8">Loading...</div>
  </div>
</template>
