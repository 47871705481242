<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { ClockIcon, MapPinIcon, MoonIcon, TagIcon } from '@heroicons/vue/24/solid'

import HeadlineText from '@/components/HeadlineText.vue'
import SidebarNavItem from '@/components/SidebarNavItem.vue'

import { sentence_case } from '@/helpers/StringHelpers'

const route = useRoute()
const section = computed(() => {
  console.debug(route.path)
  const path = route.path.split('/')[2]
  return path ? path : 'reminders'
})
</script>
<template>
  <HeadlineText
    :headline="sentence_case(section)"
    sub_head="Settings"
  />

  <main class="md:flex gap-x-6">
    <nav class="md:flex-initial">
      <ul>
        <SidebarNavItem
          :current-section="section"
          section="reminders"
          route-path="/settings/reminders"
          label="Reminders"
        >
          <ClockIcon class="size-4 text-gray inline mb-1" />
        </SidebarNavItem>
        <SidebarNavItem
          :current-section="section"
          section="phases"
          route-path="/settings/phases"
          label="Phases"
        >
          <MoonIcon class="size-4 text-gray inline mb-1" />
        </SidebarNavItem>
        <SidebarNavItem
          :current-section="section"
          section="places"
          route-path="/settings/places"
          label="Places"
        >
          <MapPinIcon class="size-4 text-gray inline mb-1" />
        </SidebarNavItem>
        <SidebarNavItem
          :current-section="section"
          section="tags"
          route-path="/settings/tags"
          label="Tags"
        >
          <TagIcon class="size-4 text-gray inline mb-1" />
        </SidebarNavItem>
      </ul>
    </nav>
    <section class="md:flex-1 md:w-64 rounded-lg p-3 bg-white dark:bg-zinc-900">
      <router-view />
    </section>
  </main>
</template>
