export const STORAGE_KEYS = {
  SEARCH_HISTORY: 'search-history',
  USER_INFO: 'user-info',
  AUTH_TOKEN: 'token',
}

export const HOURS = [
  { id: 0, name: '12:00 AM' },
  { id: 1, name: '01:00 AM' },
  { id: 2, name: '02:00 AM' },
  { id: 3, name: '03:00 AM' },
  { id: 4, name: '04:00 AM' },
  { id: 5, name: '05:00 AM' },
  { id: 6, name: '06:00 AM' },
  { id: 7, name: '07:00 AM' },
  { id: 8, name: '08:00 AM' },
  { id: 9, name: '09:00 AM' },
  { id: 10, name: '10:00 AM' },
  { id: 11, name: '11:00 AM' },
  { id: 12, name: '12:00 PM' },
  { id: 13, name: '01:00 PM' },
  { id: 14, name: '02:00 PM' },
  { id: 15, name: '03:00 PM' },
  { id: 16, name: '04:00 PM' },
  { id: 17, name: '05:00 PM' },
  { id: 18, name: '06:00 PM' },
  { id: 19, name: '07:00 PM' },
  { id: 20, name: '08:00 PM' },
  { id: 21, name: '09:00 PM' },
  { id: 22, name: '10:00 PM' },
  { id: 23, name: '11:00 PM' },
]
