import { defineStore } from 'pinia'
import { STORAGE_KEYS } from '@/constants.js'
import { Token } from '@/models/Token'
import { cleanUpStorage } from '@/helpers/LoginHelpers'

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    user: null,
    isLoggedIn: false,
  }),
  actions: {
    init() {
      const untrusted_user_info = localStorage.getItem(STORAGE_KEYS.USER_INFO)
      let untrusted_user_info_json = ''
      try {
        untrusted_user_info_json = JSON.parse(untrusted_user_info)
        const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN)

        // TODO: below check only verifies that ID in untrusted user
        // payload matches the valid token, but there are no checks that
        // the rest of what's attested in the untrusted payload is
        // verified. nothing else in there should be trusted e.g. it's
        // questionable if we should be returning confirmed; it shouldn't
        // be trusted from the client; server should always be checked
        if (parseInt(untrusted_user_info_json.id) > 0 && new Token(token).valid())
          this.login(untrusted_user_info_json)
        else {
          this.logout() // clean up
        }
      } catch (err) {
        console.debug(`Exception thrown: ${err} failed to parse untrusted_user_info; loging out`)
        this.logout() // clean up
      }
    },
    login(userData) {
      this.user = userData
      this.isLoggedIn = true
    },
    logout() {
      this.user = null
      this.isLoggedIn = false
      cleanUpStorage()
    },
    user_id() {
      return this.user.id
    },
    refresh_cached_reminder_settings(rs) {
      const stale_info = localStorage.getItem(STORAGE_KEYS.USER_INFO)
      var user_info = JSON.parse(stale_info)
      delete user_info['reminder_settings']
      user_info['reminder_settings'] = rs
      localStorage.setItem(STORAGE_KEYS.USER_INFO, JSON.stringify(user_info))
    },
  },
})
