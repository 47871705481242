<script setup>
import { colorable_classes, removable_red_classes } from '@/helpers/ColorHelpers'

const props = defineProps({
  tag: {
    type: Object,
    required: true,
  },
  mode: {
    type: String,
    required: false,
    default: undefined,
    validator: (value) => ['removable', 'non-removable'].includes(value),
  },
})

// me-2 = margin-inline, or spacing between tags → https://tailwindcss.com/docs/margin
</script>
<template>
  <span
    :class="colorable_classes(props.tag) + removable_red_classes(props.mode)"
    data-test="tag-token"
    class="border-2 me-2 mb-1 px-2.5 py-0.5 rounded-full inline-block font-medium text-sm cursor-pointer"
  >
    <slot />
  </span>
</template>
