import ApiService from '../api'
const api = ApiService()

export const format_date = (date) =>
  date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

export const user_tz_offset = () => new Date().getTimezoneOffset() * 60 * 1000

export const get_browser_tz = () => Intl.DateTimeFormat().resolvedOptions().timeZone

export const persist_last_seen_TZ = (server_timezone) => {
  if (get_browser_tz() == server_timezone) return true
  api
    .updateReminderSettings({ timezone: get_browser_tz() })
    .then(() => console.debug('synchonized new web-client TZ → server'))
}
