<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  error: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'button',
  },
})
defineEmits(['click'])

const bg_color = computed(() => {
  return props.error
    ? 'bg-red-500 border-red-600 dark:border-red-800'
    : 'bg-primary-500 border-primary-500'
})
</script>
<template>
  <button
    class="ml-auto mb-2 mt-2 px-6 py-2 rounded-lg border-2 font-bold text-white"
    :class="bg_color"
    :type="type"
    @click="$emit('click')"
  >
    <slot />
    {{ label }}
  </button>
</template>
