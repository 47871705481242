<script setup>
import { computed } from 'vue'
import { TagIcon } from '@heroicons/vue/24/outline'

import HeadlineText from '@/components/HeadlineText.vue'

import { tagged_moments_link } from '@/helpers/LinkHelpers'
import { tagLike } from '@/helpers/TagHelpers'

const props = defineProps({
  tag: {
    type: Object,
    required: true,
    validator: (t) => {
      return tagLike(t)
    },
  },
  momentsCount: {
    type: Number,
    required: true /* debatable, we could adjust the component's template to make this optional */,
  },
})

const headLine = computed(() => {
  return props.tag.name
})

const subHead = computed(() => {
  return `Tag #${props.tag.id}`
})
</script>
<template>
  <div
    class="border mb-2 p-3 rounded-md border-zinc-300 hover:border-stone-300 dark:border-zinc-700 dark:hover:border-primary-800 bg-zinc-200 dark:bg-stone-800 hover:bg-primary-50 dark:hover:bg-primary-900 text-gray-900 dark:text-white"
  >
    <HeadlineText
      :headline="headLine"
      :sub-head="subHead"
    />

    <div v-if="momentsCount > 0">
      You have
      <TagIcon class="size-4 text-gray inline mb-1" />
      <router-link
        class="font-bold underline"
        :to="tagged_moments_link(tag.id)"
      >
        {{ momentsCount }} moments
      </router-link>
      with this tag.
    </div>
  </div>
</template>
