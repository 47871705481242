<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import ApiService from '@/api'

import BaseButton from '@/components/BaseButton.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import PhaseCard from '@/components/PhaseCard.vue'

const api = ApiService()
const route = useRoute()

const phase = ref(null)
const loaded = ref(false)

async function loadPhase() {
  loaded.value = false
  const response = await api.getPhase(route.params.id)
  if (!response.ok) {
    loaded.value = 'error'
    return
  }
  phase.value = response.data
  loaded.value = true
  console.debug('loaded Phase: ', phase.value.name)
}

onMounted(() => {
  loadPhase()
})
</script>

<template>
  <div>
    <HeadlineText headline="Phase Details" />
    <PhaseCard v-if="loaded === true && phase" :phase="phase" />
    <div v-else-if="loaded === 'error'" class="text-center my-8">
      <p class="text-red-500 mb-4">Failed to load phase details</p>
      <BaseButton label="Retry" @click="loadPhase" />
    </div>
    <div v-else class="text-center my-8">Loading...</div>
  </div>
</template>
