<script setup>
import { computed } from 'vue'
import BaseNotification from '@/components/BaseNotification.vue'

import { useToasterStore } from '@/stores/ToasterStore'
const toaster = useToasterStore()

const props = defineProps({
  debug: {
    type: Boolean,
    default: false,
  },
})

const dismissNotification = (id) => {
  toaster.handleDeleteTimeout(id)
}

// container bounds highlighted for debugging
const debugContainerClasses = computed(() => {
  if (props.debug)
    return `rounded-lg space-y-2 outline outline-2 outline-blue-500/50 w-[400px] h-[300px] top-16 right-4 bg-blue-300/30`
  else return ''
})
</script>

<template>
  <div>
    <div
      class="fixed z-50 w-[400px] top-[70px] right-4"
      :class="debugContainerClasses"
    >
      <BaseNotification
        v-for="notification in toaster.notifications"
        :key="notification.id"
        :notification="notification"
        @dismiss-notification="dismissNotification"
      />
    </div>
  </div>
</template>
