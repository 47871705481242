export function moment_show_link(id_str) {
  return `/moments/${id_str}`
}

export function moment_edit_link(id_str) {
  return `/moments/edit/${id_str}`
}

export function place_show_link(id_str) {
  return `/places/${id_str}`
}

export function place_edit_link(id_str) {
  return `/places/edit/${id_str}`
}

export function phase_show_link(id_str) {
  return `/phases/${id_str}`
}

export function phase_edit_link(id_str) {
  return `/phases/edit/${id_str}`
}

export function phase_moments_link(id_str) {
  return `/phases/${id_str}/moments`
}

export function place_moments_link(id_str) {
  return `/places/${id_str}/moments`
}

export function tag_show_link(id_str) {
  return `/tags/${id_str}`
}

export function tagged_moments_link(id_str) {
  return `/tags/${id_str}/moments`
}
