<script setup>
import BaseButton from '@/components/BaseButton.vue'
import ApiService from '@/api'
const api = ApiService()

function fail() {
  // this method will return a failed response (to test error handling)
  api.testErrorHandling()
}
</script>
<template>
  <h2>Failed Response Handling Harness</h2>

  <BaseButton label="Receive a failure" @click="fail()" />
</template>
