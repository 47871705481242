<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import ApiService from '@/api'
import BaseButton from '@/components/BaseButton.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import PlaceCard from '@/components/PlaceCard.vue'

const api = ApiService()
const route = useRoute()

const place = ref(null)
const loaded = ref(false)

async function loadPlace() {
  loaded.value = false
  const response = await api.getPlace(route.params.id)
  if (!response.ok) {
    loaded.value = 'error'
    return
  }
  place.value = response.data
  loaded.value = true
}

onMounted(() => {
  loadPlace()
})
</script>

<template>
  <div>
    <HeadlineText headline="Place Details" />
    <PlaceCard v-if="loaded === true" :place="place" />
    <div v-else-if="loaded === 'error'" class="text-center my-8">
      <div class="text-red-500 mb-4">
        Failed to load place details.
        <div>View <a class="underline" href="/places">all your places</a>?</div>
      </div>
      <BaseButton label="Retry" @click="loadPlace" />
    </div>
    <div v-else class="text-center my-8">Loading...</div>
  </div>
</template>
