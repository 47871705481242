<script setup>
import { computed } from 'vue'

import { EllipsisHorizontalCircleIcon, PencilSquareIcon } from '@heroicons/vue/24/outline'

import ImagePlaceholder from '@/components/ImagePlaceholder.vue'
import MomentImageBig from '@/components/MomentImageBig.vue'
import MomentImageSmall from '@/components/MomentImageSmall.vue'
import PhaseToken from '@/components/PhaseToken.vue'
import PlaceToken from '@/components/PlaceToken.vue'
import TagsList from '@/components/TagsList.vue'

import { rm_img_from_server_and_view } from '@/helpers/ImageHelpers'
import {
  moment_show_link,
  moment_edit_link,
  phase_moments_link,
  place_moments_link,
} from '@/helpers/LinkHelpers'
import { momentLike } from '@/helpers/MomentHelpers'

const props = defineProps({
  mode: {
    type: String,
    default: '',
    validator: (value) => {
      return ['show_details', ''].includes(value)
    },
  },
  moment: {
    type: Object,
    required: true,
    validator: (m) => {
      return momentLike(m)
    },
  },
})

const show_phase = computed(() => {
  return props.moment.phase && props.moment.phase.id ? true : false
})

const show_place = computed(() => {
  return props.moment.place && props.moment.place.id ? true : false
})

const details_view = computed(() => {
  return props.mode == 'show_details' ? true : false
})
</script>
<template>
  <div v-if="details_view">
    <div v-if="moment.images.length < 1">
      <!-- ZERO images: use placeholder -->
    </div>
    <div v-else-if="moment.images.length == 1">
      <!-- ONE IMAGE: use 1 big image component -->
      <MomentImageBig
        :img="moment.images[0]"
        @remove-image="rm_img_from_server_and_view(moment, 0)"
      />
    </div>
    <div v-else-if="moment.images.length == 2">
      <!-- TWO images: use 2 X small square layout -->
      <MomentImageSmall
        :img="moment.images[0]"
        @remove-image="rm_img_from_server_and_view(moment, 0)"
      />
      <MomentImageSmall
        :img="moment.images[1]"
        @remove-image="rm_img_from_server_and_view(moment, 1)"
      />
    </div>
    <div v-else-if="moment.images.length == 3">
      <!-- THREE images: use 1 big, 2 X small square layout -->
      <MomentImageBig
        :img="moment.images[0]"
        @remove-image="rm_img_from_server_and_view(moment, 0)"
      />
      <MomentImageSmall
        :img="moment.images[1]"
        @remove-image="rm_img_from_server_and_view(moment, 1)"
      />
      <MomentImageSmall
        :img="moment.images[2]"
        @remove-image="rm_img_from_server_and_view(moment, 2)"
      />
    </div>
    <div v-else-if="moment.images.length == 4">
      <!-- FOUR images: use 2 X 2 small square layout -->
      <MomentImageSmall
        :img="moment.images[0]"
        @remove-image="rm_img_from_server_and_view(moment, 0)"
      />
      <MomentImageSmall
        :img="moment.images[1]"
        @remove-image="rm_img_from_server_and_view(moment, 1)"
      />
      <MomentImageSmall
        :img="moment.images[2]"
        @remove-image="rm_img_from_server_and_view(moment, 2)"
      />
      <MomentImageSmall
        :img="moment.images[3]"
        @remove-image="rm_img_from_server_and_view(moment, 3)"
      />
    </div>
  </div>

  <div
    class="my-2 p-2 rounded-md bg-zinc-200 border border-zinc-300 dark:bg-stone-800 dark:border-zinc-700 dark:text-white text-gray-900"
    data-test="moment-card"
  >
    <!-- content container -->
    <div
      class="flex gap-2 p-2 mt-2 rounded-lg bg-stone-300/50 dark:bg-stone-900/40 outline outline-1 dark:outline-black/20 outline-stone-300"
    >
      <!-- image placeholder for moments without images -->
      <a
        v-if="!details_view && (!moment.images || !moment.images.length)"
        :href="moment_show_link(moment.id)"
      >
        <ImagePlaceholder />
      </a>

      <div v-if="!details_view && moment.images.length > 0">
        <RouterLink
          :to="moment_show_link(moment.id)"
          class="relative block"
          :title="moment.images.length + ' images'"
        >
          <EllipsisHorizontalCircleIcon
            v-if="moment.images.length > 1"
            class="absolute size-5 text-white bottom-1 right-1"
          />
          <img
            :src="moment.images[0].xs_url"
            :srcset="moment.images[0].sm_url + ' 2x'"
            class="block h-20 min-w-28 border-3 m-auto mr-1 object-cover rounded-md dark:border-black/20"
          />
        </RouterLink>
      </div>
      <!-- end images row -->

      <!-- text flex item -->
      <div class="md:min-w-80 w-full text-sm md:text-base" data-test="moment-text">
        {{ moment.text }}
      </div>
    </div>
    <!-- end content container -->

    <div class="flex flex-row mt-2">
      <PhaseToken
        v-if="show_phase"
        :link="phase_moments_link(moment.phase.id)"
        :record="moment.phase"
      >
        {{ moment.phase.name }}
      </PhaseToken>
      <PlaceToken
        v-if="show_place"
        :link="place_moments_link(moment.place.id)"
        :record="moment.place"
      >
        {{ moment.place.name }}
      </PlaceToken>
    </div>

    <div class="flex flex-row mt-2">
      <TagsList :tags="moment.tags" mode="linkable" class="basis-3/4 grow" />

      <a :href="moment_edit_link(moment.id)" class="basis-1/8" title="Edit Moment">
        <PencilSquareIcon class="size-5 text-gray-500" />
      </a>
    </div>
  </div>
</template>
