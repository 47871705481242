<script setup>
import { onMounted, ref, watch } from 'vue'
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  notification: {
    type: Object,
    required: true,
    validator: (value) => {
      return value.id && value.message
    },
  },
})

// emit dismiss-notification to parent so state mgmt can happen at the
// parent level, which holds a reference to notifications state store
defineEmits(['dismiss-notification'])

const rootElement = ref(null)

// fade in notification
onMounted(() => {
  if (rootElement.value) {
    setTimeout(() => {
      rootElement.value.classList.add('opacity-100', 'translate-y-0')
      rootElement.value.classList.remove('opacity-0', 'translate-y-10')
    }, 10) // Minimal delay sufficient to ensure animation has time to trigger
  }
})

// fade out notification - actual deletion happens in ToasterStore
watch(
  () => props.notification.fadeOut,
  (newVal) => {
    if (newVal && rootElement.value) {
      rootElement.value.classList.remove('opacity-100', 'translate-y-0')
      rootElement.value.classList.add('opacity-0')
    }
  },
)
</script>
<template>
  <div
    ref="rootElement"
    class="p-3 mb-2 min-w-sm flex items-center transition-all duration-700 ease-in-out transform opacity-0 translate-y-10 bg-white rounded-xl shadow-lg border border-2 border-zinc-200 dark:border-primary-400 border-primary-500 text-white dark:bg-primary-900 dark:text-primary-400 text-zinc-500 text-xs"
  >
    <div
      class="flex-none px-[6px] py-[6px] mr-3 border border-2 rounded-full bg-primary-200/40 dark:bg-black border-primary-400/50 dark:border-none dark:bg-black/50 min-h-[33px] min-w-[33px]"
    >
      <InformationCircleIcon class="size-8 m-auto text-primary-500 dark:text-primary-400" />
    </div>
    <span class="flex-auto text-sm/5">{{ props.notification.message }}</span>

    <button
      class="flex-none inline-block px-2 py-0 ml-5 min-h-[30px] min-w-[30px] rounded-full bg-red-100/50 hover:bg-red-300 dark:bg-black/20 dark:hover:bg-black/70 text-lg font-bold text-black/50 hover:text-white dark:hover:text-red-500"
      @click.prevent="$emit('dismiss-notification', props.notification.id)"
    >
      ×
    </button>
  </div>
</template>
