import { STORAGE_KEYS } from '@/constants.js'

export function cleanUpStorage() {
  if (localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN))
    localStorage.removeItem(STORAGE_KEYS.AUTH_TOKEN)
  if (localStorage.getItem(STORAGE_KEYS.USER_INFO)) localStorage.removeItem(STORAGE_KEYS.USER_INFO)
  if (localStorage.getItem(STORAGE_KEYS.SEARCH_HISTORY))
    localStorage.removeItem(STORAGE_KEYS.SEARCH_HISTORY)
  if (localStorage.getItem('vitepress-theme-appearance'))
    localStorage.removeItem('vitepress-theme-appearance')
}

export function cache_user_info(user_info, token) {
  // turn into objects so we can extract, then delete search_history
  var complete_user_info = JSON.parse(user_info)
  const search_history = complete_user_info.search_history // stringified since we'll be storing it in localStorage
  delete complete_user_info.search_history

  localStorage.setItem(STORAGE_KEYS.USER_INFO, JSON.stringify(complete_user_info))
  localStorage.setItem(STORAGE_KEYS.AUTH_TOKEN, token)
  if (search_history) localStorage.setItem(STORAGE_KEYS.SEARCH_HISTORY, search_history)
}

export function getUserInfo() {
  if (!localStorage.getItem(STORAGE_KEYS.USER_INFO))
    // throw new Error("no current user in localStorage; perhaps we're not lopgged in!");
    return undefined
  else
    // lmao no explicit return
    return JSON.parse(localStorage.getItem(STORAGE_KEYS.USER_INFO))
}
