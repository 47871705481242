import { createApp } from 'vue'
import { createPinia } from 'pinia'

import { useUserInfoStore } from '@/stores/UserInfoStore'
import { init_router } from '@/router/index'
import { init_sentry } from '@/helpers/AnalyticsHelpers'

import App from './App.vue'

const pinia = createPinia()
const store = useUserInfoStore(pinia)
store.init()

const app = createApp(App).use(pinia)
const router = init_router(pinia)

init_sentry(app, router, import.meta.env.VITE_SENTRY_DSN)

app.use(router)
app.mount('#injection_point')
