<script setup>
import { computed } from 'vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

defineEmits(['menu-changed'])

const props = defineProps({
  hasErrors: {
    type: [String, Boolean],
    default: false,
  },
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  listItems: {
    type: Array,
    required: true,
    validator: (value) => {
      return value.every((item) => {
        // eslint-disable-next-line no-prototype-builtins
        return item.hasOwnProperty('id') && item.hasOwnProperty('name')
      })
    },
  },
  required: {
    type: Boolean,
  },
  selectType: {
    type: String,
    default: 'single',
  },
})

const model = defineModel({
  type: [String, Number], // kind of annoying / inconsistent
  required: true,
})

const html_id = computed(() => {
  return props.label.toLowerCase().replaceAll(/[^a-zA-Z0-9_]/gi, '_')
})

const required_field = computed(() => {
  return props.required ? true : undefined
})

const is_multiple = computed(() => {
  return props.type == 'multiple' ? true : false
})
</script>
<template>
  <fieldset class="mb-5">
    <label
      :for="html_id"
      class="block font-bold h-8 ml-1 dark:text-gray-400 text-gray-700"
    >
      <slot><!-- icon --></slot>
      {{ label }}

      <div
        v-if="hasErrors"
        class="bg-rose-100 border-rose-500/30 text-rose-500 dark:bg-rose-950/40 dark:border-rose-500/50 dark:text-rose-500 ml-1 normal-case font-normal px-3 border-2 text-sm rounded-full inline-block"
      >
        <ExclamationTriangleIcon class="size-4 ease text-gray inline mb-0.5 mr-1" />{{ hasErrors }}
      </div>
    </label>

    <select
      :id="html_id"
      v-model="model"
      :required="required_field"
      :multiple="is_multiple"
      :disabled="props.disabled"
      :class="disabled ? 'text-zinc-400 bg-gray-200' : 'bg-white'"
      class="block p-2.5 rounded-lg w-full border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-sm text-gray-900 dark:text-white dark:placeholder-gray-400 focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500 disabled:text-zinc-400 disabled:bg-gray-200 dark:disabled:text-gray-600 dark:disabled:bg-gray-800"
      @change="$emit('menu-changed')"
    >
      <option
        v-if="!is_multiple"
        value="0"
      >
        --
      </option>
      <option
        v-for="(current_item, i) in props.listItems"
        :key="i"
        :value="current_item.id"
      >
        {{ current_item.name }}
      </option>
    </select>
  </fieldset>
</template>
