// web-client/src/composables/useTag.js
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ApiService from '@/api'

export function useTag() {
  const route = useRoute()
  const tag = ref(null)
  const moments_count = ref(0)
  const loaded = ref(false)
  const error = ref(false)
  const api = ApiService()

  async function loadTag() {
    loaded.value = false
    error.value = false
    const response = await api.getTag(route.params.id)

    if (!response.ok || !response.data) {
      error.value = true
      loaded.value = true
      return
    }

    tag.value = response.data
    moments_count.value = response.data.moments_count || 0
    console.info('loaded Tag: ', tag.value.name)
    loaded.value = true
  }

  onMounted(() => {
    loadTag()
  })

  return {
    tag,
    moments_count,
    loaded,
    error,
    loadTag,
  }
}
