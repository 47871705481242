<script setup>
import NavbarTop from '@/components/NavbarTop.vue'
import NoScript from '@/components/NoScript.vue'
import NotificationsContainer from '@/components/NotificationsContainer.vue'
import { init_appearance_mode_switcher } from '@/helpers/ColorHelpers'

init_appearance_mode_switcher()
</script>

<template>
  <main class="max-w-3xl mx-auto px-2">
    <NavbarTop />
    <NotificationsContainer />
    <RouterView />
  </main>
  <NoScript />
</template>
