export function get_html_id_for_string(str) {
  if (!str) return 'generated_html_id_' + parseInt(Math.random() * 1000000000000000)
  else return str.toLowerCase().replaceAll(/[^a-zA-Z0-9_]/gi, '_')
}

export function field_classes() {
  return 'w-full mt-1 p-2.5 block rounded-lg text-sm text-gray-900 dark:text-white dark:placeholder-gray-400 invalid:dark:bg-rose-950/40 invalid:dark:border-rose-500/50 invalid:bg-rose-100 invalid:text-rose-500 invalid:outline invalid:outline-1 invalid:outline-rose-500/30 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-500 dark:focus:ring-primary-500 transition duration-300 ease' // input_fieldset_classes
}

export function email_valid(email) {
  if (!email) return false
  const email_regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/
  return email.match(email_regex)
}

export function url_valid(url) {
  if (!url) return false
  const url_regex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(:[0-9]{1,5})?(\/[^\s]*)?$/i
  return url.match(url_regex)
}
