<script setup>
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import { field_classes } from '@/helpers/FormHelpers'

defineEmits(['blur'])

defineProps({
  allow1Password: {
    type: String,
    required: false,
    default: '',
  },

  hasErrors: {
    type: [String, Boolean],
    default: '',
  },

  label: {
    type: String,
    required: true,
  },

  placeholder: {
    type: String,
    required: false,
    default: '',
  },

  rows: {
    type: Number,
    required: false,
    default: undefined,
  },

  tooltip: {
    type: String,
    required: false,
    default: '',
  },

  inputType: {
    type: String,
    required: false,
    default: 'text',
    validator: function (value) {
      return ['date', 'number', 'password', 'text', 'textarea', 'url'].includes(value)
    },
  },
})

const supported_input_types = ['date', 'number', 'password', 'text', 'url']

// defineModel({ required: true, type: Boolean })
const model = defineModel({
  required: true,
  validator: function (value) {
    if (!value) return true
    return (
      value.constructor === String || value.constructor === Date || value.constructor === Number
    )
  },
})
</script>
<template>
  <fieldset class="mb-5">
    <label class="block font-bold h-8 ml-1 dark:text-gray-400 text-gray-700">
      <slot><!-- icon --></slot>
      {{ label }}
      <a v-if="tooltip" name="tooltip" :title="tooltip">
        <InformationCircleIcon class="inline size-4 mb-1" />
      </a>

      <div
        v-if="hasErrors"
        data-test="field-error"
        class="bg-rose-100 border-rose-500/30 text-rose-500 dark:bg-rose-950/40 dark:border-rose-500/50 dark:text-rose-500 ml-1 normal-case font-normal px-3 py-0.5 border-2 text-sm rounded-full inline-block"
      >
        <ExclamationTriangleIcon class="size-4 ease text-gray inline mb-0.5 mr-1" />{{ hasErrors }}
      </div>
    </label>

    <div class="relative">
      <!-- icon positioner -->
      <input
        v-if="!inputType || supported_input_types.includes(inputType)"
        v-bind="{ 'data-1p-ignore': !allow1Password }"
        v-model.trim="model"
        :placeholder="placeholder"
        :type="inputType"
        maxlength="128"
        :class="field_classes()"
        @blur="$emit('blur')"
      />
      <textarea
        v-else-if="inputType == 'textarea'"
        v-model.trim="model"
        :rows="rows"
        class="block border p-2.5 rounded-lg text-sm w-full bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-600 dark:focus:border-primary-500 dark:focus:ring-primary-500 focus:ring-primary-500 dark:placeholder-gray-400 dark:text-white focus:border-primary-500 text-gray-900"
      />
    </div>
  </fieldset>
</template>
