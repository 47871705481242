class Token {
  constructor(str) {
    this.str = str
  }

  value() {
    return this.str
  }

  valid() {
    if (!this.str) {
      console.log('API returned an empty string instead of a token')
      return false
    }
    if (this.str.length < 100 || this.str.length > 140) {
      console.log('API returned a token of the wrong length')
      return false
    }
    if (!this.str.match(/^[\w-]+\.[\w-]+\.[\w-]+$/)) {
      console.log(this.str.split(/\./))
      console.log('API returned a token that failed a simple regex match validation')
      return false
    }
    return true
  }
}

export { Token }
