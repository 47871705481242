<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { MagnifyingGlassIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

import { record_search_item_in_history } from '@/helpers/SearchHelpers'
import BaseButton from '@/components/BaseButton.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import SearchWithHistory from '@/components/SearchWithHistory.vue'

const router = useRouter()
const term = ref('')
const isValid = ref(true)

const SEARCH_HISTORY_MAX_ITEMS = 10
console.debug(`SEARCH_HISTORY_MAX_ITEMS: ${SEARCH_HISTORY_MAX_ITEMS}`)

function handle_search_input() {
  // Trim whitespace and validate
  const searchTerm = term.value.trim()
  if (!searchTerm) {
    isValid.value = false
    return false
  }

  isValid.value = true
  record_search_item_in_history(searchTerm, SEARCH_HISTORY_MAX_ITEMS)

  router.push({
    name: 'moments#search',
    params: { term: searchTerm },
  })
}

onMounted(() => {
  document.getElementById('search_input').focus()
})
</script>

<template>
  <form action="/moments/search" method="get" @submit.prevent.once="handle_search_input">
    <HeadlineText headline="Search" sub_head="Search moment text" />

    <fieldset class="mb-5">
      <div class="relative">
        <span v-if="!isValid" class="text-red-500 font-bold">
          <ExclamationTriangleIcon class="size-4 text-gray inline mb-1" />
          Enter a search term
        </span>

        <SearchWithHistory v-model="term" @search-previous-term="handle_search_input" />
        <MagnifyingGlassIcon class="absolute w-5 h-5 top-3 right-4 text-slate-400" />
      </div>
    </fieldset>

    <div class="flex flex-wrap justify-between">
      <div class="mx-2 my-1 italic text-gray-600">Faceted search coming soon.</div>

      <BaseButton label="Search" @click.once="handle_search_input" />
    </div>
  </form>
</template>
