export function dark_mode() {
  return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
}

export function enable_dark_mode() {
  const root_el = document.querySelector('html')
  root_el.classList.add('dark')
}

export function disable_dark_mode() {
  const root_el = document.querySelector('html')
  root_el.classList.remove('dark')
}

export function colorable_classes(colorable) {
  if (isNaN(parseInt(colorable.color_id))) {
    console.debug(`error parsing color for colorable ${colorable.id}`)
    return ''
  } else {
    const color = dark_mode()
      ? // dark styles
        `bg-custom-dark-${parseInt(colorable.color_id)}/40
      hover:bg-custom-dark-${parseInt(colorable.color_id)}
      border-custom-dark-${parseInt(colorable.color_id)}/50
      text-custom-light-${parseInt(colorable.color_id)}/80
    `
      : // light styles
        `bg-custom-light-${parseInt(colorable.color_id)}
      hover:bg-custom-light-${parseInt(colorable.color_id)}/50
      border-custom-dark-${parseInt(colorable.color_id)}/30
      hover:border-custom-dark-${parseInt(colorable.color_id)}/40
      text-custom-dark-${parseInt(colorable.color_id)}/60
      hover:text-custom-dark-${parseInt(colorable.color_id)}/80
      `
    return color
  }
}

export function removable_red_classes(mode) {
  return mode == 'removable'
    ? 'hover:bg-red-300 hover:dark:bg-red-500/70 hover:text-red-600 hover:dark:text-red-200 hover:border-red-600 hover:dark:border-red-500'
    : ''
}

export function init_appearance_mode_switcher() {
  if (dark_mode()) enable_dark_mode()

  var appearance_mode = ''

  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
    appearance_mode = event.matches ? 'dark' : 'light'

    if (appearance_mode == 'dark') enable_dark_mode()
    else disable_dark_mode()
    console.debug('appearance_mode is now:', appearance_mode)
  })
}
