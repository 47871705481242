<script setup>
import { ref } from 'vue'
import { TagIcon } from '@heroicons/vue/24/outline'
import ApiService from '@/api'
import HeadlineText from '@/components/HeadlineText.vue'
import TagsPicker from '@/components/TagsPicker.vue'

const api = ApiService()
const tags = ref([])

// Load existing tags when component mounts
api.getTags().then((response) => {
  if (!response.ok) return
  tags.value = response.data
})
</script>

<template>
  <div>
    <HeadlineText headline="Tag Settings" sub-head="Meaningful facts about your moments">
      <TagIcon class="size-6 text-gray inline mb-1" />
    </HeadlineText>

    <TagsPicker v-model="tags" />
  </div>
</template>
