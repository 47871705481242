<script setup>
import { computed } from 'vue'
import { colorable_classes } from '@/helpers/ColorHelpers'
import { phase_moments_link } from '@/helpers/LinkHelpers'
import { MoonIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
  link: {
    type: String,
    required: true,
  },
})

const tokenLink = computed(() => {
  return props.link ? props.link : phase_moments_link(props.record.id)
})
</script>
<template>
  <!--  me-2 = margin-inline, or spacing between tags → https://tailwindcss.com/docs/margin -->
  <a
    :href="tokenLink"
    :title="'View all moments in: ' + props.record.name"
    :class="colorable_classes(props.record)"
    class="border-2 me-2 mb-1 px-2.5 py-0.5 rounded-lg inline-block font-medium text-xs whitespace-nowrap cursor-pointer"
    data-test="phase-token"
  >
    <MoonIcon class="size-4 inline m-1 ml-0" />
    <slot />
  </a>
</template>
