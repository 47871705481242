<script setup>
import { ref } from 'vue'

import SearchHistoryList from '@/components/SearchHistoryList.vue'

import { get_search_history } from '@/helpers/SearchHelpers.js'
import { field_classes } from '@/helpers/FormHelpers.js'

// single search term value
const model = defineModel({
  type: String,
  default: '',
})

const props = defineProps({
  searchHistory: {
    type: Array,
    required: false,
    default: undefined,
    //() => ([]), // Object and Array props must have their default value returned by a fn to ensure they are distinct for every instance of the component
    description: 'injectable for testing / storybook',
  },
})

// list of previous search terms with associated created_at dates
const search_history = props.searchHistory || ref(get_search_history())

const emit = defineEmits(['search-previous-term'])

const search_previous_term = (term) => {
  model.value = term
  // pass event up from child components to parent to trigger search
  emit('search-previous-term')
}
</script>

<template>
  <input
    id="search_input"
    v-model="model"
    autocomplete="off"
    placeholder="that time at that place"
    maxlength="64"
    :class="field_classes()"
  >

  <SearchHistoryList
    v-model="search_history"
    @search-previous-term="search_previous_term"
  />
</template>
