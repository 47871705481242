<script setup>
import { ref } from 'vue'
import SelectMenu from '@/components/SelectMenu.vue'
import ToggleCheckbox from '@/components/ToggleCheckbox.vue'
import HeaderEyebrow from '@/components/HeaderEyebrow.vue'

import { HOURS } from '@/constants.js'

const props = defineProps({
  enabled: { type: Boolean, default: true },
  respectQuietPeriods: { type: Boolean, required: true },
  quietPeriodStart: { type: Number, required: true },
  quietPeriodEnd: { type: Number, required: true },
})

const emit = defineEmits([
  'update:respectQuietPeriods',
  'update:quietPeriodStart',
  'update:quietPeriodEnd',
])

const respectQuietPeriods = ref(props.respectQuietPeriods)
const quietPeriodStart = ref(props.quietPeriodStart)
const quietPeriodEnd = ref(props.quietPeriodEnd)
</script>

<template>
  <HeaderEyebrow> Quiet Period (Pause reminders when offline / asleep) </HeaderEyebrow>

  <fieldset>
    <ToggleCheckbox
      v-model="respectQuietPeriods"
      label="Respect Quiet Periods"
      :disabled="!enabled"
      @update:model-value="emit('update:respectQuietPeriods', $event)"
    />
  </fieldset>

  <fieldset v-if="respectQuietPeriods">
    <SelectMenu
      v-model="quietPeriodStart"
      :disabled="!respectQuietPeriods || !enabled"
      label="Start Time"
      :list-items="HOURS"
      class-list="dark:disabled:bg-gray-500"
      @update:model-value="emit('update:quietPeriodStart', $event)"
    />
    <SelectMenu
      v-model="quietPeriodEnd"
      :disabled="!respectQuietPeriods || !enabled"
      label="End Time"
      :list-items="HOURS"
      @update:model-value="emit('update:quietPeriodEnd', $event)"
    />
  </fieldset>
</template>
