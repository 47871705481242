<script setup>
import { computed, ref } from 'vue'
import { Bars3Icon } from '@heroicons/vue/24/solid'

import { useUserInfoStore } from '../stores/UserInfoStore'
import NavItem from './NavItem.vue'

const store = useUserInfoStore()

const isLoggedIn = computed(() => store.isLoggedIn)

const menu_open = ref(false)

let viewport_width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
const mobile_width = 768
if (viewport_width >= mobile_width) menu_open.value = true

const handle_click = function () {
  if (viewport_width >= mobile_width) return
  menu_open.value = false
}
</script>
<template>
  <button
    class="md:hidden"
    @click="menu_open = !menu_open"
  >
    <Bars3Icon class="size-10 text-gray m-2 absolute top-1 right-1" />
  </button>

  <div
    v-if="menu_open"
    class="max-w-3xl mx-auto md:pt-2"
  >
    <nav class="border-gray-200 px-2 mb-4">
      <div class="container mx-auto flex flex-wrap items-center justify-between">
        <a
          href="#"
          class="flex"
        >
          <span class="self-center text-3xl md:text-2xl font-semibold whitespace-nowrap"> 🪴 </span>
        </a>

        <div class="md:flex justify-between items-center w-full md:w-auto md:order-1">
          <ul
            class="flex flex-col text-xl font-bold md:flex-row md:font-medium md:space-x-8 md:text-base"
          >
            <NavItem
              v-if="!isLoggedIn"
              route-path="/login"
              link-text="Login"
              @click="handle_click"
            />
            <NavItem
              v-if="isLoggedIn"
              route-path="/moments/search"
              link-text="Search"
              @click="handle_click"
            />
            <NavItem
              v-if="isLoggedIn"
              route-path="/moments"
              link-text="Moments"
              @click="handle_click"
            />
            <NavItem
              v-if="isLoggedIn"
              route-path="/moments/with_media"
              link-text="...with Media"
              @click="handle_click"
            />
            <NavItem
              v-if="isLoggedIn"
              route-path="/settings/reminders"
              link-text="Settings"
              @click="handle_click"
            />
            <NavItem
              v-if="isLoggedIn"
              route-path="/logout"
              link-text="Log out"
              @click="handle_click"
            />
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
