const BASE_URL = `${import.meta.env.VITE_API}/api/v1`
import { STORAGE_KEYS } from '@/constants.js'
import { useUserInfoStore } from '@/stores/UserInfoStore'
import { useToasterStore } from '@/stores/ToasterStore'

// TODO: refactor to use keyword (hash) args
const apiFetch = async (endpoint, options = {}, token_override, friendly_error) => {
  const url = `${BASE_URL}${endpoint}`
  const token = token_override || localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN)
  const headers = {
    Authorization: token,
    'Content-Type': 'application/json',
    ...options.headers,
  }

  try {
    const response = await fetch(url, {
      ...options,
      headers,
    })

    // Always return a consistent shape
    if (!response.ok) {
      const error = await response.json().catch(() => ({}))
      const result = {
        ok: false,
        error: error.message || response.statusText,
        status: response.status,
      }
      handleApiError(result, friendly_error)
      return result
    }

    const data = await response.json()
    return {
      ok: true,
      data,
      status: response.status,
    }
  } catch (error) {
    const result = {
      ok: false,
      error: error.message,
      status: 500,
    }
    handleApiError(result, friendly_error)
    return result
  }
}

export const handleApiError = (result, friendly_error = '') => {
  const store = useUserInfoStore()
  const toaster = useToasterStore()

  if (result.status === 401) {
    store.logout()
    return
  }
  const error_message = friendly_error || result.error
  toaster.notify({
    id: Math.random(),
    message: `${result.status}, ${error_message}`,
  })
  if (result?.error && error_message) console.error(`${error_message} - ${result.error}`)
}

const ApiService = () => ({
  root() {
    return import.meta.env.VITE_API
  },

  base() {
    return BASE_URL
  },

  getMoment(id) {
    return apiFetch(`/moments/${id}`)
  },

  getMoments(page = 1) {
    return apiFetch(`/moments/page/${page}`)
  },

  updateMoment(record) {
    console.debug('api.updateMoment(): request body: ', record)
    return apiFetch(`/moments/${record.id}`, {
      method: 'PATCH',
      body: JSON.stringify({ moment: record }),
    })
  },

  searchMoments(term, page = 1) {
    return apiFetch(`/moments/search/${term}/page/${page}`)
  },

  getMomentsWithMedia(page = 1) {
    return apiFetch(`/moments/with_media/page/${page}`)
  },

  getPhase(id) {
    console.debug(`fetching phase ${id}`)
    return apiFetch(`/phases/${id}`)
  },

  getPhases() {
    return apiFetch('/phases')
  },

  updatePhase(record) {
    const endpoint = `/phases/${record.id}`
    delete record['id']
    const payload = { phase: record }
    console.debug('api.updatePhase(): request body: ', payload)
    return apiFetch(
      endpoint,
      {
        method: 'PATCH',
        body: JSON.stringify(payload),
      },
      '',
      'Failed to update phase',
    )
  },

  createPhase(record, set_default = false) {
    var payload = { phase: record }
    if (set_default) payload = { ...payload, set_default: true }
    console.debug('api.createPhase(): request body: ', payload)

    return apiFetch(`/phases`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },

  getPlace(id) {
    console.debug(`fetching place ${id}`)
    return apiFetch(`/places/${id}`)
  },

  getPlaces() {
    return apiFetch('/places')
  },

  updatePlace(record) {
    const endpoint = `/places/${record.id}`
    delete record['id']
    const payload = { place: record }
    console.debug('api.updatePlace(): request body: ', payload)
    return apiFetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(payload),
    })
  },

  createPlace(record, set_default = false) {
    var payload = { place: record }
    if (set_default) payload = { ...payload, set_default: true }
    console.debug('api.createPlace(): request body: ', payload)

    return apiFetch(`/places`, {
      method: 'POST',
      body: JSON.stringify(payload),
    })
  },

  // TODO: don't persist social token in local storage
  getReminderSettings(id) {
    return apiFetch(`/reminder_settings/${id}`)
  },

  updateReminderSettings(rs) {
    console.debug('api.updateReminderSettings(): request body: ', rs)
    // the following ID is IGNORED by the server and hence a low
    // security risk - server does not and should not trust it.
    // Instead, @current_user based on the JWT will be used
    // We could literally pass 42069
    return apiFetch(`/reminder_settings`, {
      method: 'PATCH',
      body: JSON.stringify({ reminder_settings: rs }),
    }).then((response) => {
      console.debug('api.updateReminderSettings(): response: ', response)
      if (response.ok) {
        const store = useUserInfoStore()
        store.refresh_cached_reminder_settings(response.data)
      }
      return response
    })
  },

  updateMastodonReminderTransport(mrt) {
    console.debug('api.updateMastodonReminderTransport(): request body: ', mrt)
    return apiFetch(`/mastodon_reminder_transport`, {
      method: 'POST',
      body: JSON.stringify({ mastodon_reminder_transport: mrt }),
    })
  },

  setTransportEmail() {
    return apiFetch(`/reminder_settings/email`, { method: 'POST' })
  },

  setTransportMastodon() {
    return apiFetch(`/reminder_settings/mastodon`, { method: 'POST' })
  },

  sendTestReminder() {
    return apiFetch(`/remind`, { method: 'POST' })
  },

  getTags() {
    return apiFetch('/tags')
  },

  updateTags(tags) {
    return apiFetch('/tags', {
      method: 'PATCH',
      body: JSON.stringify({ tags }),
    })
  },

  tagMoment(moment_id, tags) {
    console.debug('tagging moment:', moment_id)
    const request_body = JSON.stringify({ moment: { id: moment_id, tag_ids: tags } })
    return apiFetch(`/moments/${moment_id}`, { method: 'PATCH', body: request_body })
  },

  createMoment(moment) {
    const request_body = JSON.stringify({
      moment: {
        aspect: moment.aspect,
        images: moment.images,
        link: moment.link,
        notes: moment.notes,
        phase_id: moment.phase_id,
        place_id: moment.place_id,
        tag_ids: moment.tag_ids,
        text: moment.text,
      },
    })
    console.debug('REQ moment: ', request_body)
    return apiFetch(`/moments`, { method: 'POST', body: request_body })
  },

  removeAttachedImage(moment_id, signed_image_id) {
    console.debug(`api.removeAttachedImage(): removing ${signed_image_id} from moment ${moment_id}`)
    return apiFetch(`/moments/${moment_id}/images/${signed_image_id}`, { method: 'DELETE' })
  },

  getMomentsForTag(id, page = 1) {
    return apiFetch(`/tags/${id}/moments/page/${page}`)
  },

  getTag(id) {
    console.debug('getting tag:', id)
    return apiFetch(`/tags/${id}`)
  },

  createTag(tag) {
    console.debug('api.createTag(): request body: ', tag)
    return apiFetch(`/tags`, {
      method: 'POST',
      body: JSON.stringify({ tag: tag }),
    })
  },

  getUser(token) {
    return apiFetch(`/user`, {}, token)
  },

  updateUser(record) {
    const payload = { user: record }
    console.debug('api.createTag(): request body: ', payload)
    return apiFetch(`/user`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
    })
  },

  getGenericData(data_request_path) {
    return apiFetch(`${data_request_path}`)
  },

  login(email, password) {
    return apiFetch(`/login`, {
      method: 'POST',
      body: JSON.stringify({ email: email, password: password }),
    })
  },

  // start confirmation flow
  sendConfirmEmail(user_id) {
    if (!user_id) {
      const store = useUserInfoStore()
      user_id = store.user_id()
    }
    return apiFetch(`/users/${user_id}/send_confirm_email`, {
      method: 'POST',
    })
  },

  // complete email confirmation
  confirmEmail(token) {
    return apiFetch(`/confirm_email`, {
      method: 'POST',
      body: JSON.stringify({ token: token }),
    })
  },

  testErrorHandling() {
    return apiFetch(`/fail`, {})
  },

  getURL() {
    return import.meta.env.VITE_URL
  },
})

export default ApiService
