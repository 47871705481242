<script setup>
import { computed } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },

  mode: {
    type: String,
    required: true,
    validator: function (value) {
      // value must match one of:
      return ['search', 'for_tag', 'with_media', 'main_feed', 'dark'].indexOf(value) !== -1
    },
  },

  to: {
    type: Function,
    required: true,
  },
})
console.debug('props.mode:', props.mode)

// applied to OUTER container (border) button, NOT inner larger surface area div
// the FIRST stanza is inactive (and contains hover styles as well)
// the SECOND stanza is active
// rainbow gradient appears when hovered (inactive) or when button is active (filter-mode:dark)
// but only when the browser or the user settings are in DARK mode
const dark_button_active_classes = computed(() => {
  return props.mode == 'dark'
    ? `bg-slate-700
text-slate-200
border-primary-500

dark:bg-primary-500
dark:bg-linear-to-r
dark:from-indigo-400
dark:via-purple-500
dark:to-pink-500

dark:hover:bg-linear-to-t
dark:hover:from-stone-500
dark:hover:to-zinc-300

`
    : `

text-slate-500
dark:hover:bg-gray-700
dark:border-slate-600
hover:bg-gray-300
hover:text-slate-200

hover:bg-linear-to-r
hover:from-yellow-500
hover:via-red-500
hover:to-pink-500

`
})
</script>
<template>
  <button
    class="inline-block mt-2 mb-2 ml-2 p-0.5 rounded-lg bg-gray-200 dark:bg-gray-800 dark:text-white font-bold text-slate-400"
    :class="dark_button_active_classes"
    @click="to"
  >
    <div
      class="px-6 py-[7px] rounded-[6px] bg-gray-700 dark:bg-gray-800 active:bg-gray-800 hover:bg-gray-900 text-white"
    >
      {{ label }}
    </div>
  </button>
</template>
