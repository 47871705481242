<script setup>
import { phase_show_link, phase_edit_link } from '@/helpers/LinkHelpers'
import { PencilSquareIcon } from '@heroicons/vue/24/outline'

import { colorable_classes } from '@/helpers/ColorHelpers'
import NerdInfo from '@/components/NerdInfo.vue'

const props = defineProps({
  phase: {
    type: Object,
    required: true,
  },
})
</script>
<template>
  <div
    :class="colorable_classes(props.phase)"
    class="mb-2 p-3 rounded-md border border-2 hover:border-stone-300 dark:hover:border-primary-800 dark:hover:bg-primary-900 hover:bg-primary-50"
  >
    <NerdInfo class="relative">
      <a :href="phase_show_link(phase.id)">#{{ phase.id }}</a>

      <a
        :href="phase_edit_link(phase.id)"
        title="Edit"
        class="absolute border-red-900 mb-1 md:absolute right-0 pr-0"
      >
        <PencilSquareIcon class="size-5 text-gray dark:text-white/50" />
      </a>
    </NerdInfo>
    <span> {{ phase.name }} {{ phase.description ? ' · ' + phase.description : '' }} </span>
  </div>
</template>
