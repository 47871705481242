import { createWebHistory, createRouter } from 'vue-router'
import { STORAGE_KEYS } from '@/constants.js'

import AuthConfirmEmail from '@/views/AuthConfirmEmail.vue'
import AuthNope from '@/views/AuthNope.vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import NotFound from '@/components/NotFound.vue'

import MomentForm from '@/views/MomentForm.vue'
import MomentSearch from '@/views/MomentSearch.vue'
import MomentShow from '@/views/MomentShow.vue'
import MomentsIndex from '@/views/MomentsIndex.vue'

import PhaseForm from '@/views/PhaseForm.vue'
import PhaseShow from '@/views/PhaseShow.vue'
import PhasesIndex from '@/views/PhasesIndex.vue'

import PlaceForm from '@/views/PlaceForm.vue'
import PlaceShow from '@/views/PlaceShow.vue'
import PlacesIndex from '@/views/PlacesIndex.vue'

import TagShow from '@/views/TagShow.vue'
import TagsPicker from '@/components/TagsPicker.vue'

import SettingsView from '@/views/SettingsView.vue'
import ReminderSettings from '@/views/ReminderSettings.vue'
import TagSettings from '@/views/TagSettings.vue'

import CentralResponseErrorHandlingTest from '@/views/CentralResponseErrorHandlingTest.vue'

import { useUserInfoStore } from '@/stores/UserInfoStore'

function titleize(s) {
  //return `${s} · ${app_title}`;
  return s
}

const app_title = 'Bright Moments*'

export function init_router() {
  const store = useUserInfoStore()

  const routes = [
    {
      path: '/',
      component: AuthNope,

      meta: {
        title: app_title,
        metaTags: [
          { name: 'description', content: 'Gratitude rediscovery' },
          { property: 'og:description', content: 'Active Digital Health' },
        ],
      },
    },

    {
      path: '/login',
      component: LoginView,
      meta: {
        title: 'Log In',
        metaTags: [{ name: 'description', content: 'Gratitude rediscovery .' }],
      },
    },
    {
      path: '/logout',
      component: AuthNope,
      beforeEnter: (to, from, next) => {
        console.debug('calling logout_callback() which should be store.logout()')
        store.logout()
        router.push('/')
        next() // seems demented, hopefully we don't need?
      },
      meta: { title: app_title },
    },

    {
      path: '/home',
      component: HomeView,
      meta: {
        requiresAuth: true,
        title: titleize('Home'),
      },
    },

    {
      path: '/moments/:id',
      component: MomentShow,
      name: 'moments#show',
      meta: {
        requiresAuth: true,
        title: titleize(`Moment Details`),
      },
    },

    {
      path: '/moments/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments#main_feed',
      meta: {
        requiresAuth: true,
        title: app_title,
      },
    },

    {
      path: '/moments/new',
      component: MomentForm,
      name: 'moments#new',
      meta: {
        requiresAuth: true,
        title: titleize('Add New Moment'),
      },
    },

    {
      path: '/moments/edit/:id',
      component: MomentForm,
      name: 'moments#edit',
      meta: {
        requiresAuth: true,
        title: titleize('Edit Moment'),
      },
    },

    {
      path: '/moments/search',
      component: MomentSearch,
      name: 'moments#searchInput',
      meta: {
        requiresAuth: true,
        title: titleize('Search'),
      },
    },

    {
      path: '/moments/search/:term/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments#search',
      meta: {
        requiresAuth: true,
        title: titleize('Search Results'),
      },
    },

    {
      path: '/moments/with_media/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments#with_media',
      meta: {
        requiresAuth: true,
        title: titleize('Moments with Media'),
      },
    },

    {
      path: '/moments/dark/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments#dark',
      meta: {
        requiresAuth: true,
        title: titleize('Dark Moments'),
      },
    },

    {
      path: '/phases',
      component: PhasesIndex,
      name: 'phases#index',
      meta: {
        requiresAuth: true,
        title: titleize('Phases'),
      },
    },

    {
      path: '/phases/:id',
      component: PhaseShow,
      name: 'phases#show',
      meta: {
        requiresAuth: true,
        title: titleize('Phase'),
      },
    },

    {
      path: '/phases/new',
      component: PhaseForm,
      name: 'phases#new',
      meta: {
        requiresAuth: true,
        title: titleize('Add Phase'),
      },
    },

    {
      path: '/phases/edit/:id',
      component: PhaseForm,
      name: 'phases#edit',
      meta: {
        requiresAuth: true,
        title: titleize('Edit Phase'),
      },
    },

    {
      path: '/phases/:phase_id/moments/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments_for_phase',
      meta: {
        requiresAuth: true,
        title: titleize('Moments (Filtered by Phase)'),
      },
    },

    {
      path: '/places',
      component: PlacesIndex,
      name: 'places#index',
      meta: {
        requiresAuth: true,
        title: titleize('Places'),
      },
    },

    {
      path: '/places/:id',
      component: PlaceShow,
      name: 'places#show',
      meta: {
        requiresAuth: true,
        title: titleize('Place'),
      },
    },

    {
      path: '/places/new',
      component: PlaceForm,
      name: 'places#new',
      meta: {
        requiresAuth: true,
        title: titleize('Add Place'),
      },
    },

    {
      path: '/places/edit/:id',
      component: PlaceForm,
      name: 'places#edit',
      meta: {
        requiresAuth: true,
        title: titleize('Edit Place'),
      },
    },

    {
      path: '/places/:place_id/moments/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments_for_place',
      meta: {
        requiresAuth: true,
        title: titleize('Moments (Filtered by Place)'),
      },
    },

    {
      path: '/tags/:id',
      component: TagShow,
      name: 'tag#details',
      meta: {
        requiresAuth: true,
        title: titleize('Tag Details'),
      },
    },

    {
      path: '/tags/:tag_id/moments/:separator?/:page?',
      component: MomentsIndex,
      name: 'moments_for_tag',
      meta: {
        requiresAuth: true,
        title: titleize('Moments (Filtered by Tag)'),
      },
    },

    {
      path: '/tags/picker',
      component: TagsPicker,
      name: 'tags#picker',
      meta: {
        requiresAuth: true,
        title: titleize('Tags'),
      },
    },

    {
      path: '/settings',
      component: SettingsView,
      redirect: '/settings/reminders', // load reminders panel by default
      children: [

        {
          path: 'reminders', // relative to parent /settings
          component: ReminderSettings,
          meta: {
            requiresAuth: true,
            title: titleize('Reminders'),
          },
        },

        {
          path: 'phases',
          component: PhasesIndex,
          meta: {
            requiresAuth: true,
            title: titleize('Phases'),
          },
        },

        {
          path: 'places',
          component: PlacesIndex,
          meta: {
            requiresAuth: true,
            title: titleize('Places'),
          },
        },

        {
          path: 'tags',
          component: TagSettings,
          meta: {
            requiresAuth: true,
            title: titleize('Tags'),
          },
        },
      ],
      meta: { requiresAuth: true },
    },

    {
      path: '/confirm_email/token/:token',
      component: AuthConfirmEmail,
      meta: {
        requiresAuth: false,
        title: titleize('Confirm Email'),
        // TODO redirect if successful / fail etc
      },
    },

    {
      path: '/fail',
      component: CentralResponseErrorHandlingTest,
      meta: {
        requiresAuth: true,
        title: titleize('Fail'),
      },
    },

    {
      path: '/:pathMatch(.*)',
      component: NotFound,
      meta: { requiresAuth: true },
    },
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      const token = localStorage.getItem(STORAGE_KEYS.AUTH_TOKEN)
      if (token) {
        next() // User is authenticated, proceed to the route
      } else {
        next('/login') // User is not authenticated, redirect to login
      }
    } else {
      // Non-protected route, allow access
      next()
    }
  })

  // callback runs before every route change, including on page load.
  router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags)

    const previousNearestWithMeta = from.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title
    } else if (previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
      el.parentNode.removeChild(el),
    )

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')

        return tag
      })
      // Add the meta tags to the document head.
      .forEach((tag) => document.head.appendChild(tag))

    next()
  })

  return router
}
