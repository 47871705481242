<script setup>
import { onMounted, ref } from 'vue'

import { tagLike } from '@/helpers/TagHelpers'

// Array of tags
const model = defineModel({
  required: true,
  type: Array,
  validator: (value) => {
    return Array.isArray(value) && value.every((item) => tagLike(item))
  },
})
const props = defineProps({
  momentId: {
    type: Number,
    required: false,
    default: -1,
  },
})

import ApiService from '@/api'
import InputFieldset from '@/components/InputFieldset.vue'
import TagsList from '@/components/TagsList.vue'

const loaded = ref(false)
const tags = ref([])
const applied_tags = ref([])
const candidate_tags = ref([])
const user_input_tag_text = ref('')
const api = ApiService()
const mode = parseInt(props.momentId) > 0 ? 'update' : 'create'

// TODO MIGHT BE SMART to refactor this to use one base array and
// decorate its items with an attribute like .applied.
// then updating and filtering becomes pretty simple:
//
//
// applied:     tags.filter(t => t.applied)
// candidates:  tags.filter(t => !t.applied)
// apply: tag = tags.find(t => t.id == tag_id); tag.applied = true; re-rum applied, above
// remove: same as apply but in reverse

function check_applied_and_check_user_input(tag) {
  const applied_ids = applied_tags.value.map((at) => at.id)

  return (
    !applied_ids.includes(tag.id) &&
    tag.name.toLowerCase().indexOf(user_input_tag_text.value.toLowerCase()) > -1
  )
}

// filter candidate based on user input
function update_candidate_tags(e) {
  if (!Array.isArray(tags.value)) return
  candidate_tags.value = tags.value.filter((tag) => check_applied_and_check_user_input(tag))
  if (e && e.key == 'Enter') {
    const existing_tags = tag_exists_for_user(user_input_tag_text.value)
    if (existing_tags.length > 0) {
      console.debug(
        `ENTER for *existing* tag ${user_input_tag_text.value}, apply it IF NOT APPLIED`,
      )
      apply_tag(existing_tags[0])
      reset_picker()
    } else if (existing_tags.length == 0) {
      create_tag_and_reset_picker()
    }
  }
}

function tag_exists_for_user(name) {
  if (!Array.isArray(tags.value)) return []
  return tags.value.filter((tag) => tag.name.toLowerCase() == name.toLowerCase())
}

async function create_tag(name) {
  const tagResponse = await api.createTag({ name: name })
  if (!tagResponse.ok) {
    console.debug('Error creating tag:', tagResponse.error)
    return null
  }
  const new_tag = tagResponse.data.tag
  if (Array.isArray(tags.value)) {
    tags.value.push(new_tag)
  } else { // do we ever reach this branch?
    tags.value = [new_tag]
  }
  return { status: 'Tag created', tag: new_tag }
}

function reset_picker() {
  user_input_tag_text.value = ''
}

function create_tag_and_reset_picker() {
  create_tag(user_input_tag_text.value).then(
    (result) => {
      if (!result) return
      console.debug(result)
      candidate_tags.value = Array.isArray(tags.value) ? tags.value : []
      handle_apply_tag(undefined, tags.value[tags.value.length - 1])
    },
    (error) => {
      console.debug('Failed to create tag:', error)
    },
  )
}

onMounted(() => {
  applied_tags.value = Array.isArray(model.value) ? model.value : []
  api.getTags().then((tagsResponse) => {
    if (!tagsResponse.ok) return
    tags.value = Array.isArray(tagsResponse.data) ? tagsResponse.data : []
    console.debug(`fetched ${tags.value.length} tags from the api.`)
    console.debug(`${model.value.length} incoming tags for moment ${props.momentId}`)

    update_candidate_tags()
    subtract_applied_tags_from_candidates()
    loaded.value = true
  })
})

function subtract_applied_tags_from_candidates() {
  const applied_ids = applied_tags.value.map((at) => at.id)
  candidate_tags.value = tags.value.filter((t) => !applied_ids.includes(t.id))
}

// remove is NOT delete; this removes tag from the moment; rename to unTag?
function handle_remove_tag(_, tag) {
  applied_tags.value = applied_tags.value.filter((t) => t.id != tag.id)
  candidate_tags.value.push(tag)
  if (mode == 'create') {
    model.value = applied_tags.value
  } else {
    api
      .tagMoment(
        props.momentId,
        applied_tags.value.map((t) => t.id),
      )
      .then(
        (updateMomentTagsResponse) => {
          console.debug(
            `updated moment ${props.momentId}: tags counts is now: ${updateMomentTagsResponse.data.tags.length}`,
          )
        },
        (error) => {
          console.debug(`error applying tags to moment id ${props.momentId}`)
          console.debug(error)
        },
      )
  }
}

function handle_apply_tag(_, tag) {
  return apply_tag(tag)
}

function rm_tag_from_candidates(tag) {
  candidate_tags.value = candidate_tags.value.filter((t) => t.id != tag.id)
}

function tag_applied(tag) {
  return applied_tags.value.find((t) => t.id == tag.id)
}

function apply_tag(tag) {
  reset_picker()
  if (tag_applied(tag)) {
    console.debug('edge case: tag requested already applied; resetting tags picker)')
    subtract_applied_tags_from_candidates()
    return
  }

  applied_tags.value.push(tag)
  rm_tag_from_candidates(tag)
  // TODO this fn is mis-used or at best misnamed;
  // just need to remove filters and reset. tag already removed
  subtract_applied_tags_from_candidates()

  if (mode == 'create') {
    model.value = applied_tags.value
  } else {
    api
      .tagMoment(
        props.momentId,
        applied_tags.value.map((t) => t.id),
      )
      .then(
        (updateMomentTagsResponse) => {
          console.debug(
            `updated moment ${props.momentId}: tags counts is now: ${updateMomentTagsResponse.data.tags.length}`,
          )
        },
        (error) => {
          console.debug(`error applying tags to moment id ${props.momentId}`)
          console.debug(error)
        },
      )
  }
}

/*
- [x] Remove moment's applied tags from candidate tags list
- [x] Filter (narrow) tags list on user text field input
- [x] catch ENTER event AFTER filtering the tags list
- [x] if input matches a candidate tag, apply it
- [x] if input (after an ENTER) does NOT match any candidate tag,
- [x] Create tag
- [x] Allow creation of new tags whose name are a subset of an existing tag
- [x] Clear input and reset tags list filter
- [x] Removable tags can be removed
- [x] Candidate tags can be applied by clicking
- [x] Apply tag after creation in UI
- [x] Apply tag after creation in Service, either at create time or apply it subsequently
- [x] added candidate tags are removed from candidates list when applied
*/
</script>
<template>
  <div v-if="loaded" class="tag-picker">
    <div class="my-2">
      <fieldset class="mb-5">
        <TagsList
          :tags="applied_tags"
          mode="removable"
          @remove-tag="handle_remove_tag(_, $event)"
        />
      </fieldset>
    </div>
    <div class="my-2">
      <InputFieldset
        v-model="user_input_tag_text"
        label="Type a tag (or phrase) and press ENTER to create a new tag."
        input-type="text"
        placeholder="Type a tag"
        @keyup="update_candidate_tags($event)"
        @enter.prevent
      />
      <!-- NOTE: this DISPLAYS a list of prop.tags; it does not FETCH tags, bake cakes, etc. -->
      <TagsList :tags="candidate_tags" mode="applyable" @apply-tag="handle_apply_tag(_, $event)" />
    </div>
  </div>
</template>
