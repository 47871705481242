<script setup>
import { computed, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import { EnvelopeIcon } from '@heroicons/vue/24/outline'
import { ShieldExclamationIcon } from '@heroicons/vue/24/outline'
import { LockClosedIcon } from '@heroicons/vue/24/outline'

import ApiService from '@/api'

import { Token } from '@/models/Token'
import { useUserInfoStore } from '@/stores/UserInfoStore'
import { useToasterStore } from '@/stores/ToasterStore'

import BaseButton from '@/components/BaseButton.vue'
import InputFieldset from '@/components/InputFieldset.vue'

import { email_valid } from '@/helpers/FormHelpers'
import { persist_last_seen_TZ } from '@/helpers/DateHelpers'
import { cache_user_info } from '@/helpers/LoginHelpers'

const store = useUserInfoStore()
const toaster = useToasterStore()

const form_data = reactive({
  email: {
    untouched: true,
    value: '',
  },
  password: {
    untouched: true,
    value: '',
  },
})

const api = ApiService()
const router = useRouter()
const last_login_failed = ref(false)
const loading = ref(false)

function touch_and_validate_email() {
  form_data.email.untouched = false
  validate_email.value // call computed
}

const validate_email = computed(() => {
  if (form_data.email.untouched) return ''
  reset_form_submit_status()
  const success = email_valid(String(form_data.email.value))
  return success ? '' : 'Check your email address'
})

const validate_password = computed(() => {
  if (form_data.password.untouched) return ''
  reset_form_submit_status()
  return form_data.password.value.length >= 8 ? '' : 'Not a full password'
})

async function performLogin(email, password) {
  console.debug('performLogin start')
  loading.value = true
  try {
    const response = await api.login(email, password)
    if (!response.ok) {
      last_login_failed.value = true
      loading.value = false
      return null
    }
    return response.data
  } catch (err) {
    toaster.notify({
      id: Math.random(),
      message: `Error: ${err.message}`,
    })
    store.logout()
    loading.value = false
    return null
  }
}

const reset_form_submit_status = function () {
  last_login_failed.value = false
}

const validate_form = function () {
  console.debug('validate_form called')
  // Mark fields as touched when form is submitted
  form_data.email.untouched = false
  form_data.password.untouched = false

  if (validate_password.value !== '' || validate_email.value !== '') {
    console.debug('validation failed:', {
      email: validate_email.value,
      password: validate_password.value,
    })
    reset_form_submit_status()
    return false
  }

  console.debug('validation passed, calling performLogin')
  performLogin(form_data.email.value, form_data.password.value).then((response) => {
    console.debug('performLogin response:', response)
    if (!response) return // Error already handled by performLogin

    /* SUCCESS */
    const token = new Token(response.token)

    if (!token.valid()) {
      toaster.notify({
        id: Math.random(),
        message: 'Invalid authentication token received',
      })
      loading.value = false
    } else {
      cache_user_info(JSON.stringify(response.user), token.value())
      persist_last_seen_TZ(response.user?.reminder_settings?.timezone)
      store.login(response.user)
      loading.value = false
      router.push('/moments')
    }
  })
}
</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2
        class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100"
        data-test="login-message"
      >
        Sign in to your account
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="py-8 px-4 shadow sm:rounded-lg sm:px-10 bg-white dark:bg-gray-800">
        <form class="space-y-6" @submit.prevent="validate_form">
          <InputFieldset
            v-model="form_data.email.value"
            :has-errors="validate_email"
            :untouched="form_data.email.untouched"
            allow-1-password="true"
            autocomplete="email"
            data-test="email"
            input-type="text"
            label="Email address"
            name="email"
            @blur="touch_and_validate_email"
          >
            <EnvelopeIcon class="inline size-4 mb-1 mr-1" />
          </InputFieldset>

          <InputFieldset
            v-model="form_data.password.value"
            :has-errors="validate_password"
            :untouched="form_data.password.untouched"
            allow-1-password="true"
            autocomplete="current-password"
            data-test="password"
            input-type="password"
            label="Password"
            name="password"
          >
            <LockClosedIcon class="inline size-4 mb-1 mr-1" />
          </InputFieldset>

          <div v-if="last_login_failed" class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <ShieldExclamationIcon aria-hidden="true" class="h-5 w-5 text-red-400" />
              </div>
              <div class="ml-3">
                <h3 data-test="login-error" class="text-sm font-medium text-red-800">
                  Invalid credentials
                </h3>
              </div>
            </div>
          </div>

          <div>
            <BaseButton
              data-test="login-button"
              :disabled="loading"
              :label="loading ? 'Signing in...' : 'Sign in'"
              class="w-full"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
