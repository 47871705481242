import ApiService from '@/api'
import { useToasterStore } from '@/stores/ToasterStore'

// performs server-side removal using the API
export async function moment_remove_image_link(moment_id, image_signed_id) {
  const api = ApiService()
  if (confirm('Remove this image permanently?')) {
    return await api.removeAttachedImage(moment_id, image_signed_id)
  }
}

// convenience wrapper that fires off server-side removal and waits to
// return a status to the caller
export async function rm_img_by_index(moment, index) {
  return await moment_remove_image_link(moment.id, moment.images[index].signed_id).then(
    (response) => {
      return response
    },
  )
}

// client-side DOM orchestrator method that invokes the convenience
// method above them removes the image object from the client-side ref,
// causing a re-render of the image array after a successful deletion
export function rm_img_from_server_and_view(moment, index) {
  const toaster = useToasterStore()
  rm_img_by_index(moment, index).then((response) => {
    if (!response.ok || !response.data.status.match(/image deleted success/i)) {
      // Note that this declaration needs to be within function;
      // otherwise it attempt to create a duplicate pinia, which fails noisily

      toaster.notify({
        id: Math.random(),
        message: `Failed to delete image from server: ${response.status}`,
      })
      return
    }
    toaster.notify({
      id: Math.random(),
      message: `Image deleted.`,
    })
    // by removing the reference to the server-deleted image
    // from the reactive array, the image gallery will re-render
    // with the shortened images list, using an appropriate template
    moment.images.splice(index, 1)
    // TODO: replace with new toasty notifications
  })
}
