<script setup>
defineProps({
  label: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['toggle'])

const model = defineModel({ required: true, type: Boolean })
</script>
<template>
  <label class="inline-flex items-center cursor-pointer">
    <input
      v-model="model"
      type="checkbox"
      value="Reminders"
      :disabled="disabled"
      class="sr-only peer"
      @change="$emit('toggle')"
    >
    <div
      class="relative w-11 h-6 bg-gray-200 peer-focus:outline-hidden peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:rtl:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600 peer-checked:dark:bg-primary-500"
    />
    <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{{ label }}</span>
  </label>
</template>
