<script setup>
import '@/assets/loading.css'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DirectUpload } from '@rails/activestorage'
import {
  CalendarIcon,
  ClipboardIcon,
  LinkIcon,
  MapPinIcon,
  MoonIcon,
  PhotoIcon,
  SunIcon,
} from '@heroicons/vue/24/outline'

import ApiService from '@/api'

import { useToasterStore } from '@/stores/ToasterStore'
import { url_valid } from '@/helpers/FormHelpers'
import { rm_img_from_server_and_view } from '@/helpers/ImageHelpers'

import AspectPicker from '@/components/AspectPicker.vue'
import BaseButton from '@/components/BaseButton.vue'
import FieldLabel from '@/components/FieldLabel.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import InputFieldset from '@/components/InputFieldset.vue'
import MomentImageSmall from '@/components/MomentImageSmall.vue'
import SelectMenu from '@/components/SelectMenu.vue'
import TagsPicker from '@/components/TagsPicker.vue'

const route = useRoute()
const router = useRouter()
const api = ApiService()
const toaster = useToasterStore()

const loaded = ref(false)
const error = ref(false)

const id = ref(undefined)
const mode_update = route.params.id ? true : false
const reactive_keys = ['aspect', 'est_event_date', 'link', 'notes', 'phase_id', 'place_id', 'text']
const form_data = reactive({})
reactive_keys.forEach((rk) => (form_data[rk] = { untouched: true, value: '' }))

// SET DEFAULTS
form_data.aspect.value = 'bright'
var tags = []
const tags_ready = ref(false)

const get_defaults = function () {
  api.getUser().then((userResponse) => {
    if (!userResponse.ok) return

    if (!form_data.phase_id.value && userResponse.data.user.default_phase_id)
      form_data.phase_id.value = userResponse.data.user.default_phase_id
    if (!form_data.place_id.value && userResponse.data.user.default_place_id)
      form_data.place_id.value = userResponse.data.user.default_place_id
  })
}

// ENSURE modified items are marked touched
watch(form_data, () => {
  const ensure_touched = function (key) {
    if (key != 'link' && form_data[key].untouched && form_data[key].value != '')
      form_data[key].untouched = false
  }
  reactive_keys.forEach((k) => ensure_touched(k))
})

// REACTIVE ARRAYS for associated objects
const phases = ref([])
const places = ref([])
let images = ref([])
const btn = reactive({
  loading: false,
  label: 'Save',
})

let moment = {}
let images_to_attach = []

const data_direct_upload_url = function () {
  return `${api.root()}/rails/active_storage/direct_uploads`
}

const uploadFile = (file) => {
  const url = document.getElementById('moment_images').dataset.directUploadUrl
  const upload = new DirectUpload(file, url)

  upload.create((error, blob) => {
    console.group('🐰Race Runner: ActiveStorage Upload.create FINISHED')
    if (error) {
      toaster.notify({
        id: Math.random(),
        message: `Unable to upload images.`,
      })
    } else {
      images_to_attach.push(blob.signed_id)
      post_moment_if_input_valid()
    }
    console.groupEnd()
  })
}

const validate_text = computed(() => {
  if (form_data.text.untouched) return false
  return form_data.text.value.length >= 2 ? '' : 'Text should be at least 3 characters.'
})

const validate_phase_selection = computed(() => {
  if (form_data.phase_id.untouched) return false
  return form_data.phase_id.value > 0 ? '' : 'Select a phase '
})

const validate_place_selection = computed(() => {
  if (form_data.place_id.untouched) return false
  return form_data.place_id.value > 0 ? '' : 'Select a place'
})

const validate_link = computed(() => {
  if (form_data.link.untouched) return false
  return url_valid(form_data.link.value) ? '' : 'Check that this URL is correct'
})

const btn_loading_classes = computed(() => {
  return btn.loading ? 'loading' : ''
})

function touch_and_validate_link() {
  form_data.link.untouched = false
  validate_link.value
}

const validate_form_and_upload_media = function () {
  const errors = []

  // mark everything touched
  reactive_keys.forEach((rk) => {
    if (rk != 'link') form_data[rk].untouched = false
  })

  if (validate_text.value) errors.push('Moment text should not be blank.')

  const phase_selected = document.getElementById('phase').selectedIndex > 0 ? true : false
  if (!phase_selected) errors.push('Choose a phase.')

  const place_selected = document.getElementById('place').selectedIndex > 0 ? true : false
  if (!place_selected) errors.push('Choose a place.')

  if (errors.length > 0) {
    toaster.notify({
      id: Math.random(),
      message: errors.join(' '),
    })
    errors.splice(0)
    btn.loading = false
    return
  } else {
    btn.loading = true
    btn.label = 'Saving'

    const selected_images = document.getElementById('moment_images').files
    if (selected_images.length == 0) {
      console.debug('no images in this moment to upload, so call: post_moment_if_input_valid()')
      post_moment_if_input_valid()
    } else {
      // fire off ASYNC ActiveStorage attachment uploads
      Array.from(selected_images).forEach(function (image, index) {
        image.isUploading = true
        uploadFile(image, index)
      })
    }
  }
}

const post_moment_if_input_valid = function () {
  if (id.value) moment.id = id.value

  moment.est_event_date = form_data.est_event_date.value
  moment.link = form_data.link.value
  moment.notes = form_data.notes.value
  moment.phase_id = form_data.phase_id.value
  moment.place_id = form_data.place_id.value
  moment.tag_ids = tags.map((t) => t.id)
  moment.text = form_data.text.value

  // merge any existing images in the document with any newly uploaded images to attach
  const existing_image_elements = document.getElementsByClassName('existing_image')
  for (let tag of existing_image_elements) {
    if (tag.value) images_to_attach.push(tag.value)
  }

  moment.images = images_to_attach

  const radios = document.querySelectorAll('input[name=aspect]')
  // NO IDEA why, when uploading an image, this is undefined:
  if (radios && radios[0] && radios[0].checked) moment.aspect = 0
  else moment.aspect = 1

  console.debug(moment)

  updateOrCreateMoment(moment).then((updateResponse) => {
    if (!updateResponse.ok) {
      error.value = true
      btn.loading = false
      btn.label = 'Failed :('
      return
    }
    console.debug('updateResponse.moment: ', updateResponse)
    let next_route_id_param = {}
    if (moment.id)
      // edit existing
      next_route_id_param = { id: moment.id }
    else if (updateResponse.data.id)
      // create
      next_route_id_param = { id: updateResponse.data.id }

    toaster.notify({
      id: Math.random(),
      message: `Moment saved.`,
    })
    router.push({
      name: 'moments#show',
      params: next_route_id_param,
    })
    console.groupEnd()
  })
  console.groupEnd()
}

const updateOrCreateMoment = async function (moment) {
  try {
    if (id.value) return await api.updateMoment(moment)
    else return await api.createMoment(moment)
  } catch (err) {
    console.debug(err.message) // TODO handle 422 API error: Unprocessable Content
  }
}

onMounted(() => {
  api.getPhases().then((phasesResponse) => {
    // TODO handle failed loading of Phases or Places more stringently
    // should we consider the entire view to failed and offer option to retry?
    if (!phasesResponse.ok) {
      error.value = true
      return
    }
    phases.value = phasesResponse.data
  })
  api.getPlaces().then((placesResponse) => {
    if (!placesResponse.ok) {
      error.value = true
      return
    }
    places.value = placesResponse.data
  })

  get_defaults()
})

if (mode_update) {
  console.debug(`Found an id param, get Moment #${route.params.id} from API`)

  // response handler after fetching record
  api.getMoment(route.params.id).then((momentResponse) => {
    if (!momentResponse.ok) {
      // TODO: implement retry logic in case of server error?
      error.value = true
      router.push({ name: 'moments#index' })
      return
    }

    id.value = momentResponse.data.id

    form_data.aspect.value = momentResponse.data.aspect
    form_data.text.value = momentResponse.data.text
    form_data.notes.value = momentResponse.data.notes
    form_data.link.value = momentResponse.data.link
    form_data.phase_id.value = momentResponse.data.phase.id
    form_data.place_id.value = momentResponse.data.place.id
    form_data.est_event_date.value = momentResponse.data.est_event_date

    // assigned separately
    console.debug(
      `service sent us ${momentResponse.data.tags.length} tags:`,
      momentResponse.data.tags,
    )
    tags = momentResponse.data.tags
    tags_ready.value = true

    images.value = momentResponse.data.images

    loaded.value = true
    console.debug(`loaded Moment #${id.value}:${form_data.text.value}`)
    console.debug(`loaded ${images.value.length} images: `)
  })
} else {
  console.debug('creating a new moment, handle tags TBD')
  loaded.value = true
  tags_ready.value = true
}
</script>

<template>
  <form
    v-if="loaded"
    action="/moments"
    method="post"
    @submit.prevent="validate_form_and_upload_media"
  >
    <HeadlineText headline="Moment" />

    <AspectPicker v-model="form_data.aspect.value" />

    <InputFieldset
      v-model="form_data.text.value"
      :rows="3"
      input-type="textarea"
      label="Text"
      tooltip="Why this moment mattered"
      placeholder="Remember when..."
    >
      <SunIcon class="size-4 text-gray inline mb-1" />
    </InputFieldset>

    <SelectMenu
      v-model="form_data.phase_id.value"
      :has-errors="validate_phase_selection"
      :list-items="phases"
      :required="true"
      select-type="single"
      label="Phase"
    >
      <MoonIcon class="size-4 text-gray inline mb-1" />
    </SelectMenu>

    <SelectMenu
      v-model="form_data.place_id.value"
      :has-errors="validate_place_selection"
      :list-items="places"
      :required="true"
      select-type="single"
      label="Place"
    >
      <MapPinIcon class="size-4 text-gray inline mb-1" />
    </SelectMenu>

    <TagsPicker v-if="tags_ready" v-model="tags" :moment-id="id" />

    <fieldset class="mb-5">
      <FieldLabel label-for="image" text="Media">
        <PhotoIcon class="size-4 text-gray inline mb-1" />
      </FieldLabel>
      <div class="mx-1 md:text-xl lg:text-xl font-bold">
        <section class="flex">
          <!-- TODO rm_img_from_server_and_view is a bit long for an inline lol -->
          <MomentImageSmall
            v-for="(image, i) in images"
            :key="i"
            :img="image"
            class="inline-block mt-3 mb-4 mr-2"
            @remove-image="
              rm_img_from_server_and_view({ id: parseInt(route.params.id), images: images }, i)
            "
          />
        </section>

        <div v-for="(image, i) in images" :key="i">
          <input
            :value="image.signed_id"
            class="existing_image"
            name="moment[images][]"
            type="hidden"
          />
        </div>

        <!-- from rails clean-room learnings -->
        <input autocomplete="off" name="moment[images][]" type="hidden" value="" />
        <!-- NOTE: ID in the below element is significant because it's a reference for JS logic to perform .dataset.directUploadUrl -->
        <input
          id="moment_images"
          :data-direct-upload-url="data_direct_upload_url()"
          multiple="multiple"
          name="moment[images][]"
          accept=".jpg, .jpeg, .png, .webp, .heic"
          type="file"
          class="file:my-2 file:px-4 file:py-2 file:rounded-lg file:border-0 file:text-white file:font-bold file:text-base file:bg-primary-500 file:hover:bg-primary-400"
        />
      </div>
    </fieldset>

    <InputFieldset
      v-model="form_data.link.value"
      input-type="url"
      label="Link"
      :has-errors="validate_link"
      tooltip="Optional URL. Not included in reminder messages"
      @blur="touch_and_validate_link"
    >
      <LinkIcon class="size-4 text-gray inline mb-1" />
    </InputFieldset>

    <InputFieldset
      v-model="form_data.notes.value"
      input-type="textarea"
      label="Notes"
      :rows="4"
      tooltip="Optional. Additional notes to yourself. Not included in reminder messages"
    >
      <ClipboardIcon class="size-4 text-gray inline mb-1" />
    </InputFieldset>

    <InputFieldset
      v-model="form_data.est_event_date.value"
      input-type="date"
      label="Estimated Date"
      tooltip="Optional. Simply a year, or an exact date: entirely up to you."
    >
      <CalendarIcon class="size-4 text-gray inline mb-1" />
    </InputFieldset>

    <fieldset class="flex">
      <BaseButton
        :align-right="true"
        :label="btn.label"
        :error="error"
        :class="btn_loading_classes"
        @click="validate_form_and_upload_media"
      />
    </fieldset>
  </form>
</template>
