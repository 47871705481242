<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useTag } from '@/composables/useTag'

import TagCard from '@/components/TagCard.vue'
import BaseButton from '@/components/BaseButton.vue'
import ApiService from '@/api'

const route = useRoute()
const api = ApiService()

const { tag, moments_count } = useTag()
const loaded = ref(false)
const error = ref(false)

onMounted(() => {
  api.getTag(route.params.id).then((response) => {
    if (!response.ok) {
      error.value = true
      return
    }
    tag.value = response.data.tag
    moments_count.value = response.data.moments_count ? parseInt(response.data.moments_count) : 0
    console.info('loaded Tag: ', tag.value.name)
    loaded.value = true
  })
})
</script>
<template>
  <div v-if="error" class="text-center my-8">
    <p class="text-red-500 mb-4">Failed to find tag :(</p>
    <BaseButton label="Retry" @click="$router.go()" />
  </div>
  <TagCard v-else-if="loaded" :tag="tag" :moments-count="moments_count" />
</template>
