<script setup>
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline'

import ApiService from '@/api'
import ButtonDarkBorder from '@/components/ButtonDarkBorder.vue'
import ButtonInList from '@/components/ButtonInList.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import MomentCard from '@/components/MomentCard.vue'
import BaseButton from '@/components/BaseButton.vue'

const api = ApiService()
const route = useRoute()
const router = useRouter()

// watch route params and reload data when they change
// supports re-rendering when changing routes (moments#search → moments#list)
// or navigating between pages like page/1 page/2
// when component tree remains the same
watch(
  // 60-line watch method lol
  () => route.params,
  // eslint-disable-next-line
  async (newparams) => {
    console.debug('watcher responding to updated params: reset: moments, mode, page')

    let query_to_fetch
    if (route.path != route_for_mode(mode.value)) {
      moments.value = [] // ensure reset
      page.value = page_from_route()
      if (mode.value != get_mode()) mode.value = get_mode()
      query_to_fetch = route_with_page(route.path, page.value)
    } else {
      query_to_fetch = route_for_mode(mode.value)
    }

    // Reset tag data when changing routes
    if (!route.params.tag_id) {
      tag.value = { name: '', id: null }
      moments_count.value = 0
    }

    // Load main data
    api.getGenericData(query_to_fetch).then((momentsFeedResponse) => {
      if (!momentsFeedResponse.ok) {
        loaded.value = 'error'
        return
      }
      meta.value = momentsFeedResponse.data.meta
      moments.value = momentsFeedResponse.data.data
      loaded.value = true
    })

    // Load tag data if we're on a tag route
    if (route.params.tag_id) {
      api.getTag(route.params.tag_id).then((tagResponse) => {
        if (!tagResponse.ok) return
        const tagData = tagResponse.data

        // Ensure we have valid tag data
        if (!tagData || !tagData.name) {
          console.error('Invalid tag data received:', tagData)
          return
        }

        tag.value = {
          id: tagData.id,
          name: tagData.name,
          moments_count: tagData.moments_count || 0,
        }
        console.debug('Tag value after assignment:', tag.value)
        moments_count.value = tagData.moments_count || 0
        console.debug('loaded Tag name:', tag.value.name)
        console.debug('loaded Tag object:', tag.value)

        optional_tags_title_modifier = ` tagged ${tag.value.name}`
        title.value = 'Moments' + optional_tags_title_modifier
      })
    }
  },
)

const meta = ref({})
const moments = ref([])
const moments_count = ref(0)
const page = ref(1)
const tag = ref({ name: '', id: null })
const phase = ref({ name: '' })
const place = ref({ name: '' })
const title = ref('Moments')
const mode = ref('main_feed')
const loaded = ref(false)

if (route.params.page) page.value = route.params.page

mode.value = get_mode()
page.value = page_from_route() // pagination
api.getGenericData(route_with_page(route.path, page.value)).then((momentsFeedResponse) => {
  if (!momentsFeedResponse.ok) {
    loaded.value = 'error'
    return
  }
  meta.value = momentsFeedResponse.data.meta
  moments.value = momentsFeedResponse.data.data
  loaded.value = true
})

// TODO: extract
let optional_tags_title_modifier = ''
if (route.params.tag_id) {
  loaded.value = false
  api.getTag(route.params.tag_id).then((tagResponse) => {
    if (!tagResponse.ok) {
      loaded.value = 'error'
      return
    }
    loaded.value = true
    const tagData = tagResponse.data
    tag.value = {
      id: tagData.tag.id,
      name: tagData.tag.name,
      moments_count: tagData.moments_count,
    }
    moments_count.value = tagData.moments_count || 0
    console.debug('loaded Tag: ', tag.value.name)

    optional_tags_title_modifier = ` tagged ${tag.value.name}`
    title.value = 'Moments' + optional_tags_title_modifier
  })
}

if (route.params.phase_id) {
  loaded.value = false
  api.getPhase(route.params.phase_id).then((phaseResponse) => {
    if (!phaseResponse.ok) {
      loaded.value = 'error'
      return
    }
    phase.value = phaseResponse.data
    loaded.value = true
  })
}

if (route.params.place_id) {
  loaded.value = false
  api.getPlace(route.params.place_id).then((placeResponse) => {
    if (!placeResponse.ok) {
      loaded.value = 'error'
      return
    }
    place.value = placeResponse.data
    loaded.value = true
  })
}

function get_mode() {
  if (route.params.term) return 'search'
  else if (route.params.tag_id) return 'for_tag'
  else if (route.params.phase_id) return 'for_phase'
  else if (route.params.place_id) return 'for_place'
  else if (route.path.match('/moments/with_media')) return 'with_media'
  else if (route.path.match('/moments/dark')) return 'dark'
  else return 'main_feed'
}

function page_from_route() {
  const match = route.path.match(/\/page\/([0-9]+)/)
  return match ? match[1] : 1
}

// add pagination to the client-side route for consistency so that all routes behave like they have an explicit page #, even the first
function route_with_page(route, page) {
  if (route.match('/page/[0-9]+')) return route.replace(/([\w-_]+\/page\/)([0-9]+)/, `$1${page}`)
  else return `${route}/page/${page}`
}

function visit_page(page_to_visit) {
  const route_to = route_for_mode(mode.value)
  router.push({ path: route_with_page(route_to, page_to_visit) })
}

function visit_dark() {
  mode.value = 'dark'
  visit_page(1)
}

// TODO: these SEEM to be client-side routes, but how do we specify the correct API route for a given query?
function route_for_mode(mode_value) {
  const ModeRouter = {
    search: `/moments/search/${route.params.term}`,
    for_tag: `/tags/${route.params.tag_id}/moments`,
    for_phase: `/phases/${route.params.phase_id}/moments`,
    for_place: `/places/${route.params.place_id}/moments`,
    with_media: `/moments/with_media`,
    main_feed: `/moments`,
    dark: `/moments/dark`,
  }
  return ModeRouter[mode_value]
}

const count_summary_headline = computed(() => {
  if (!meta.value) return
  const cnt = meta.value.total_count == undefined ? 0 : meta.value.total_count
  const unit = cnt == 1 ? 'moment' : 'moments'
  return cnt + ' ' + unit
})

const show_leading_btn = computed(() => {
  switch (mode.value) {
    case 'for_tag':
    case 'with_media':
    case 'main_feed':
    case 'dark':
      return true
    default:
      return false
  }
})

const light_button_active_classes = computed(() => {
  return mode.value == 'dark'
    ? 'dark:border-slate-600 text-primary-500 hover:text-black'
    : 'text-white bg-primary-500 border-primary-500'
})

const dark_button_active_classes = computed(() => {
  return mode.value != 'dark'
    ? 'text-slate-500 dark:hover:bg-gray-700 dark:border-slate-600 hover:bg-gray-300 hover:text-slate-800'
    : 'bg-slate-700 text-slate-200 dark:bg-primary-500 border-primary-500 hover:bg-gray-500'
})

const modalTitle = computed(() => {
  const mode = get_mode()
  const tagName = tag.value?.name || ''

  const ModeTitles = {
    search: 'Search Results',
    for_tag: `${moments_count.value} tagged: ${tagName}`,
    for_phase: `During phase: ${phase.value.name}`,
    for_place: `In place: ${place.value.name}`,
    with_media: 'With attached images',
    main_feed: '',
    dark: 'Dark Moments',
  }
  return ModeTitles[mode]
})

// Add retry function
function retryLoad() {
  loaded.value = false
  // Retry the main data load
  api.getGenericData(route_with_page(route.path, page.value)).then((momentsFeedResponse) => {
    if (!momentsFeedResponse.ok) {
      loaded.value = 'error'
      return
    }
    meta.value = momentsFeedResponse.data.meta
    moments.value = momentsFeedResponse.data.data
    loaded.value = true
  })

  // Retry any additional data loads based on route params
  if (route.params.tag_id) {
    api.getTag(route.params.tag_id).then((tagResponse) => {
      if (!tagResponse.ok) return
      const tagData = tagResponse.data
      tag.value = {
        id: tagData.id,
        name: tagData.name,
        moments_count: tagData.moments_count,
      }
      moments_count.value = tagData.moments_count || 0
      optional_tags_title_modifier = ` tagged ${tag.value.name}`
      title.value = 'Moments' + optional_tags_title_modifier
    })
  }

  if (route.params.phase_id) {
    api.getPhase(route.params.phase_id).then((phaseResponse) => {
      if (!phaseResponse.ok) return
      phase.value = phaseResponse.data
    })
  }

  if (route.params.place_id) {
    api.getPlace(route.params.place_id).then((placeResponse) => {
      if (!placeResponse.ok) return
      place.value = placeResponse.data
    })
  }
}
</script>
<template>
  <div>
    <HeadlineText
      data-test-sub-headline="moments-index-sub-header"
      headline="Moments"
      :sub-head="modalTitle"
    />
    <ButtonInList v-if="show_leading_btn" label="Create New Moment" route-path="/moments/new" />
    <div v-if="loaded === 'error'" class="text-center my-8">
      <p class="text-red-500 mb-4">Failed to load data</p>
      <BaseButton label="Retry" @click="retryLoad" />
    </div>
    <div v-else-if="loaded">
      <div class="flex justify-between">
        <HeadlineText headline="" :sub_head="count_summary_headline" />
        <div v-if="mode == 'main_feed' || mode == 'dark'">
          <!-- Bodda me? About a Routa link? Bodda me about a routa link?! -->
          <router-link
            to="/moments"
            class="mb-2 mt-2 px-6 py-2 rounded-lg border-2 hover:bg-primary-400 hover:border-primary-400 active:bg-primary-300 font-bold dark:text-white"
            :class="[light_button_active_classes]"
            data-test="light-moments-button"
          >
            Light
          </router-link>
          <ButtonDarkBorder
            :mode="mode"
            label="Dark"
            :to="visit_dark"
            :class="dark_button_active_classes"
            data-test="dark-moments-button"
          />
        </div>
      </div>

      <div v-if="moments.length === 0" class="text-center my-8 text-gray-600">
        No moments found.
      </div>
      <ul v-else>
        <li v-for="(m, i) in moments" :key="i">
          <MomentCard :moment="m" />
        </li>
      </ul>

      <nav v-if="meta && moments.length > 0" data-test="pagination" class="block my-10 text-center">
        <a
          v-if="meta.prev_page"
          :href="meta.prev_page"
          title="back"
          data-test="prev"
          @click.prevent="visit_page(meta.prev_page)"
        >
          <ChevronLeftIcon class="inline-block size-4" />
        </a>
        …
        <a
          v-if="meta.next_page"
          :href="meta.next_page"
          title="forward"
          data-test="next"
          @click.prevent="visit_page(meta.next_page)"
        >
          <ChevronRightIcon class="inline-block size-4" />
        </a>
      </nav>
    </div>
    <div v-else class="text-center my-8">Loading...</div>
  </div>
</template>
