<template>
  <svg
    class="fill-cyan-500 hover:fill-cyan-700"
    width="48px"
    height="48px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="bot-head"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        id="left-eye"
        fill="#ff0000"
        d="
            M11.7227,13.0962 C10.4327,13.0962 9.3887,14.1402 9.3887,15.4292 C9.3887,16.7182
            10.4327,17.7622 11.7227,17.7622 C13.0097,17.7622 14.0557,16.7182 14.0557,15.4292
            C14.0557,14.1402 13.0097,13.0962 11.7227,13.0962
          "
      />
      <path
        id="head"
        fill="#00c8ff"
        d="M30,30 L2,30 L2,22 L30,22 L30,30 Z M5,17.112 C5,12.159 9.727,7.13 15.5,6.659
            L15.5,4.908 C14.918,4.702 14.499,4.153 14.499,3.5 C14.499,2.671 15.171,2 15.999,2
            C16.827,2 17.499,2.671 17.499,3.5 C17.499,4.152 17.081,4.701 16.5,4.908 L16.5,6.659
            C22.273,7.13 27,12.159 27,17.112 L27,21 L5,21 L5,17.112 Z M30,20 L29,20 L29,17.112 C29,11.684
            24.672,6.641 19.107,5.102 C19.359,4.616 19.499,4.069 19.499,3.5 C19.499,1.57 17.929,0
            15.999,0 C14.069,0 12.499,1.57 12.499,3.5 C12.499,4.07 12.639,4.617 12.891,5.102
            C7.327,6.642 3,11.685 3,17.112 L3,20 L2,20 C0.896,20 0,20.895 0,22 L0,30 C0,31.104
            0.896,32 2,32 L30,32 C31.105,32 32,31.104 32,30 L32,22 C32,20.895 31.105,20 30,20 L30,20 Z
          "
      />
      <path
        id="right-eye"
        fill="#00ff00"
        d="M20.2773,13.0962 C18.9883,13.0962 17.9443,14.1402 17.9443,15.4292 C17.9443,16.7182
            18.9883,17.7622 20.2773,17.7622 C21.5663,17.7622 22.6113,16.7182 22.6113,15.4292
            C22.6113,14.1402 21.5663,13.0962 20.2773,13.0962
          "
      />
    </g>
  </svg>
</template>
