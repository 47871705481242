import * as Sentry from '@sentry/vue'

export function init_sentry(app, router, dsn) {
  var sentry_obj

  if (import.meta.env.PROD) {
    sentry_obj = Sentry.init({
      app,
      dsn: dsn,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      enabled: import.meta.env.PROD,

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
      // We recommend adjusting this value in production
      // https://bit.ly/sentry-traces
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      tracePropagationTargets: ['localhost', /^https?:\/\/api.brightmoments.app/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // https://bit.ly/sentry-session-reply
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  } else {
    sentry_obj = Sentry.init({
      app,
      dsn: undefined,
      integrations: [],
      enabled: false,
    })
  }

  return sentry_obj
}
