import ApiService from '@/api'
import { STORAGE_KEYS } from '@/constants.js'

export function get_search_history() {
  const untrusted = localStorage.getItem(STORAGE_KEYS.SEARCH_HISTORY)
  if (untrusted) return JSON.parse(localStorage.getItem(STORAGE_KEYS.SEARCH_HISTORY))
  return []
}

export function delete_history_item(term) {
  write_search_history(get_search_history().filter((item) => item.term != term))
  return true
}

export function record_search_item_in_history(search_term, max_items = 10) {
  var search_history = []
  if (localStorage.getItem(STORAGE_KEYS.SEARCH_HISTORY))
    search_history = JSON.parse(localStorage.getItem(STORAGE_KEYS.SEARCH_HISTORY))

  search_history = search_history.filter((item) => item.term != search_term)

  search_history.splice(0, 0, { term: search_term, created_at: Date.now() })
  console.debug(
    `added search_term '${search_term}' to history; ${search_history.length} items saved in history`,
  )

  if (search_history.length > max_items) search_history.length = max_items

  write_search_history(search_history)
  return search_history
}

function write_search_history(search_history) {
  const api = ApiService()
  const search_string = JSON.stringify(search_history)
  localStorage.setItem(STORAGE_KEYS.SEARCH_HISTORY, search_string)

  api.updateUser({ search_history: search_string }) // response ignored, not super critical
}
