<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import ApiService from '@/api'
import { useUserInfoStore } from '@/stores/UserInfoStore'
import { useToasterStore } from '@/stores/ToasterStore'
import { cache_user_info } from '@/helpers/LoginHelpers'
import BaseButton from '@/components/BaseButton.vue'
import HeadlineText from '../components/HeadlineText.vue'

const api = ApiService()
const store = useUserInfoStore()
const toaster = useToasterStore()
const route = useRoute()
const router = useRouter()
const token = route.params.token
const mode = ref('WAITING')

async function confirmEmailWithToken() {
  mode.value = 'WAITING'
  const response = await api.confirmEmail(token)

  if (!response.ok) {
    if (response.error) {
      toaster.notify({
        id: Math.random(),
        message: response.error,
      })
    }
    mode.value = 'UNKNOWN_FAIL'
    return
  }

  if (!response.data) {
    mode.value = 'FAIL'
    return
  }

  const status = response.data.status || ''
  if (status?.match(/Already verified/)) {
    mode.value = 'PREVIOUSLY_CONFIRMED'
    return
  }

  if (status?.match(/expired/)) {
    // Don't pass user_id here - the API will use the current user from the JWT
    const resendConfirmationEmailResponse = await api.sendConfirmEmail()
    if (!resendConfirmationEmailResponse.ok && resendConfirmationEmailResponse.error) {
      toaster.notify({
        id: Math.random(),
        message: resendConfirmationEmailResponse.error,
      })
    }
    mode.value = 'EXPIRED'
    return
  }

  if (status && response?.data?.token && status?.match(/Email confirm.*token/i)) {
    mode.value = 'SUCCESS'
    const userResponse = await api.getUser(response.data.token)
    if (!userResponse.ok) {
      if (userResponse.error) {
        toaster.notify({
          id: Math.random(),
          message: userResponse.error,
        })
      }
      mode.value = 'UNKNOWN_FAIL'
      return
    }
    cache_user_info(JSON.stringify(userResponse.data.user), response.data.token)
    store.login(userResponse.data.user)
    router.push('/moments')
  } else {
    mode.value = 'UNKNOWN_FAIL'
  }
}

confirmEmailWithToken()
</script>

<template>
  <div>
    <HeadlineText headline="Email Confirmation" />

    <div v-if="mode === 'WAITING'" class="text-center my-8">Confirming your email...</div>

    <div v-else-if="mode === 'SUCCESS'" class="text-center my-8">Success! Redirecting you...</div>

    <div v-else-if="mode === 'PREVIOUSLY_CONFIRMED'" class="text-center my-8">
      Your email was already confirmed. You can close this window.
    </div>

    <div v-else-if="mode === 'EXPIRED'" class="text-center my-8">
      <p class="mb-4">Your confirmation link has expired. Check your inbox for a new one.</p>
      <p>Click the new confirmation link to complete confirmation.</p>
    </div>

    <div v-else class="text-center my-8">
      <p class="text-red-500 mb-4">Failed to confirm your email</p>
      <BaseButton label="Try Again" @click="confirmEmailWithToken" />
    </div>
  </div>
</template>
