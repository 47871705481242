<script setup>
import SearchHistoryItem from '@/components/SearchHistoryItem.vue'
import { delete_history_item } from '@/helpers/SearchHelpers.js'

defineEmits(['search-previous-term'])

// Array: recent search terms: {term: '...', created_at: '1738623254816'}
const model = defineModel({
  required: true,
  type: Array,
  validator: (value) => value.every((item) => item.term && item.created_at),
})

const delete_previous_term = (term) => {
  if (delete_history_item(term)) model.value = model.value.filter((item) => item.term != term)
}
</script>

<template>
  <div
    v-if="model.length"
    class="mt-1 rounded-xl bg-zinc-100/50 dark:bg-zinc-800 border border-1 border-zinc-300 dark:border-zinc-600"
  >
    <ul>
      <SearchHistoryItem
        v-for="(search_history_item, i) in model"
        :key="i"
        :term="search_history_item.term"
        :created-at="search_history_item.created_at"
        @search-previous-term="$emit('search-previous-term', $event)"
        @delete-previous-term="delete_previous_term"
      />
    </ul>
  </div>
</template>
