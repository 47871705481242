import { defineStore } from 'pinia'

const NOTIF_TIMEOUT = 3000

export const useToasterStore = defineStore('toaster', {
  state() {
    return {
      notifications: [],
    }
  },
  actions: {
    notify(notification) {
      notification.fadeOut = false
      this.notifications.push(notification)
      this.handleDeleteTimeout(notification.id, NOTIF_TIMEOUT)
    },

    deleteNotification(id) {
      this.notifications = this.notifications.filter((notification) => notification.id !== id)
    },

    handleDeleteTimeout(id, timeout) {
      setTimeout(() => {
        const notification = this.notifications.find((n) => n.id === id)
        if (notification) {
          notification.fadeOut = true
          setTimeout(() => this.deleteNotification(id), 1000) // Allow time for fade-out animation
        }
      }, timeout)
    },
  },
})
