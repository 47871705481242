<script setup>
import { CheckCircleIcon, MoonIcon, XCircleIcon } from '@heroicons/vue/24/solid'

const model = defineModel({
  required: true,
  type: String,
  validator: (value) => ['exclude', 'include', 'after_dark'].includes(value),
  default: 'exclude',
})

defineProps({
  enabled: {
    type: Boolean,
    default: true,
  },
})
</script>
<template>
  <fieldset class="mb-5">
    <ul
      class="md:w-72 w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
    >
      <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="radio-dark-reminders-exclude"
            v-model="model"
            type="radio"
            name="radio-dark-reminders"
            value="exclude"
            :disabled="!enabled"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="radio-dark-reminders-exclude"
            class="w-full py-3 ms-2 text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            <XCircleIcon class="size-5 text-red-500 inline mb-1" />
            Exclude from Reminders</label
          >
        </div>
      </li>

      <li class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="radio-dark-reminders-include"
            v-model="model"
            type="radio"
            name="radio-dark-reminders"
            value="include"
            :disabled="!enabled"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="radio-dark-reminders-include"
            class="w-full py-3 ms-2 text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            <CheckCircleIcon class="size-5 text-green-500 inline mb-1" />
            Include in Reminders</label
          >
        </div>
      </li>

      <li class="w-full border-gray-200 rounded-t-lg dark:border-gray-600">
        <div class="flex items-center ps-3">
          <input
            id="radio-dark-reminders-after-dark"
            v-model="model"
            type="radio"
            name="radio-dark-reminders"
            value="after_dark"
            :disabled="!enabled"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="radio-dark-reminders-after-dark"
            class="w-full py-3 ms-2 text-sm font-medium text-gray-600 dark:text-gray-300"
          >
            <MoonIcon class="size-5 text-purple-400 inline" />
            Only include after dark (~7 PM)</label
          >
        </div>
      </li>
    </ul>
  </fieldset>
</template>
