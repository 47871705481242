<script setup>
import { onMounted, ref } from 'vue'
import { MapPinIcon } from '@heroicons/vue/24/outline'

import ApiService from '@/api'

import BaseButton from '@/components/BaseButton.vue'
import ButtonInList from '@/components/ButtonInList.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import PlaceCard from '@/components/PlaceCard.vue'

const api = ApiService()
const places = ref([])
const loaded = ref(false)

async function loadPlaces() {
  loaded.value = false
  const response = await api.getPlaces()
  if (!response.ok) {
    loaded.value = 'error'
    return
  }
  places.value = response.data
  loaded.value = true
}

onMounted(() => {
  loadPlaces()
})
</script>

<template>
  <div>
    <HeadlineText headline="" sub-head="Significant locations">
      <MapPinIcon class="size-6 text-gray inline mb-1" />
    </HeadlineText>

    <ButtonInList label="Create New Place" route-path="/places/new" />

    <div v-if="loaded === true">
      <ul>
        <li v-for="(place, i) in places" :key="i">
          <PlaceCard :place="place" />
        </li>
      </ul>
    </div>
    <div v-else-if="loaded === 'error'" class="text-center my-8">
      <p class="text-red-500 mb-4">Failed to load places</p>
      <BaseButton label="Retry" @click="loadPlaces" />
    </div>
    <div v-else class="text-center my-8">Loading...</div>
  </div>
</template>
