<script setup>
import { computed, onMounted, ref } from 'vue'

import BotSvg from '@/components/BotSvg.vue'
import BaseButton from '@/components/BaseButton.vue'
import HeaderEyebrow from '@/components/HeaderEyebrow.vue'
import InputFieldset from '@/components/InputFieldset.vue'
import SimpleCard from '@/components/SimpleCard.vue'

import { AtSymbolIcon, CheckCircleIcon, EnvelopeIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
  enabled: {
    type: Boolean,
    default: true,
  },
})

import ApiService from '@/api'
const api = ApiService()
const confirmed = ref(false)
const email = ref('')
const private_bot_host = ref('')
const private_bot_mention_user = ref('')
const private_bot_token = ref('')
const private_bot_setup_complete = ref(false)
const loaded = ref(false)
const active_transport = ref()
const bot_settings_saved = ref(false)

function confirm_email() {
  if (confirmed.value) return
  api.sendConfirmEmail(api.getUser())
}

function save_bot_settings() {
  const payload = {
    token: private_bot_token.value,
    host: private_bot_host.value,
    mention_user: private_bot_mention_user.value,
  }
  api.updateMastodonReminderTransport(payload).then((updateBotSettingsResponse) => {
    if (!updateBotSettingsResponse.ok) {
      bot_settings_saved.value = false
      return
    }
    // updateBotSettingsResponse.data contains the saved fields; not currently used
    bot_settings_saved.value = true
  })
}

const email_confirmation_warning = computed(() => {
  return confirmed.value ? '' : 'Email not confirmed'
})

const email_confirm_button_label = computed(() => {
  return confirmed.value ? 'Email confirmed' : 'Resend Confirmation Link'
})

const email_confirm_button_classes = computed(() => {
  return confirmed.value
    ? 'pl-[9px] bg-white dark:bg-primary-900/50 text-zinc-800 dark:text-primary-400 '
    : 'text-black'
})

const bot_setup_incomplete_warning = computed(() => {
  return private_bot_setup_complete.value ? '' : 'Complete bot setup to receieve rediscoveries'
})

const bot_settings_button_label = computed(() => {
  return bot_settings_saved.value ? 'Bot settings saved' : 'Save bot settings'
})

const bot_settings_button_classes = computed(() => {
  return bot_settings_saved.value ? '' : 'bg-white pl-[9px] font-bold text-zinc-800'
})

const bot_settings_button_icon_color = computed(() => {
  return bot_settings_saved.value ? 'text-white' : 'text-primary-500'
})

const transport_email = computed(() => {
  return active_transport.value == 'EmailReminderTransport' ? true : false
})

const transport_bot = computed(() => {
  return active_transport.value == 'MastodonReminderTransport' ? true : false
})

function select_email() {
  if (!props.enabled) return
  api.setTransportEmail().then(() => {
    active_transport.value = 'EmailReminderTransport'
  })
}

function select_bot() {
  if (!props.enabled) return
  api.setTransportMastodon().then(() => {
    active_transport.value = 'MastodonReminderTransport'
  })
}

onMounted(() => {
  api.getUser().then((userResponse) => {
    loaded.value = false
    if (!userResponse.ok) return
    const u = userResponse.data.user
    confirmed.value = u.confirmed
    email.value = u.email
    active_transport.value = u.reminder_settings.reminder_transport_type
    private_bot_host.value = u.reminder_settings.private_bot.host
    private_bot_token.value = u.reminder_settings.private_bot.token
    private_bot_mention_user.value = u.reminder_settings.private_bot.mention_user
    loaded.value = true
  })
})
</script>

<template>
  <!-- Transport Selector -->
  <div
    class="bg-white dark:bg-zinc-900 border-zinc-100 dark:border-zinc-800 text-sm transition-all duration-300 ease-in-out"
  >
    <!-- Selector Cards -->
    <div class="flex m-0 p-0 my-6">
      <SimpleCard
        :selected="transport_email"
        class="flex justify-center align-center text-center cursor-pointer"
        @click="select_email"
      >
        <figure class="mt-6">
          <EnvelopeIcon class="m-auto size-12" />
          Email
        </figure>
      </SimpleCard>
      <SimpleCard
        :selected="transport_bot"
        class="flex justify-center align-center text-center cursor-pointer"
        @click="select_bot"
      >
        <figure class="mt-6">
          <bot-svg class="m-auto" />
          Private Bot
        </figure>
      </SimpleCard>
    </div>

    <!-- Config Container -->
    <div
      class="focus:w-36 focus:outline-hidden border-1 border border-zinc-100 rounded-lg shadow-md px-5 pt-3 mb-4 bg-white dark:bg-zinc-900 border-zinc-100 dark:border-zinc-800 text-sm transition-all duration-300 ease-in-out"
    >
      <!-- Email Config -->
      <div v-if="active_transport == 'EmailReminderTransport'">
        <HeaderEyebrow>
          <EnvelopeIcon class="size-5 inline-block mb-2px" />
          Email
        </HeaderEyebrow>

        <fieldset class="mb-4">
          <label class="block">Rediscoveries will deliver to: </label>
          <input
            v-if="loaded"
            disabled
            class="px-2 py-1 rounded-md border border-1 border-zinc-200 dark:border-zinc-500 dark:bg-zinc-700 text-zinc-400"
            :value="email"
          />
        </fieldset>

        <div v-if="loaded">
          <span class="italic text-zinc-500">
            {{ email_confirmation_warning }}
          </span>
          <fieldset>
            <BaseButton
              :label="email_confirm_button_label"
              :class="email_confirm_button_classes"
              class="font-bold"
              @click.once="confirm_email"
            >
              <CheckCircleIcon
                v-if="confirmed"
                class="size-5 mr-1 font-bold text-green-500 inline-block"
              />
            </BaseButton>
          </fieldset>
        </div>
      </div>

      <!-- Mastodon Bot Config -->
      <div v-if="active_transport == 'MastodonReminderTransport'">
        <HeaderEyebrow class="mb-2">
          <AtSymbolIcon class="size-5 inline-block mb-2px" />
          Private Bot
        </HeaderEyebrow>

        <div class="text-zinc-400 mb-5">Your private bot setup.</div>

        <fieldset>
          <InputFieldset
            v-model="private_bot_host"
            label="Server Hostname"
            input-type="text"
            placeholder="http://mastodon.server"
            :disabled="!enabled"
            tooltip="server host name"
          />

          <InputFieldset
            v-model="private_bot_token"
            label="Access Token"
            input-type="password"
            placeholder="0x123DECAFBAD000"
            :disabled="!enabled"
            tooltip="your private access token"
          />

          <InputFieldset
            v-model="private_bot_mention_user"
            label="User to @mention in private reminder"
            input-type="text"
            placeholder="@you"
            :disabled="!enabled"
            tooltip="This helps force a notification on your phone."
          />
        </fieldset>

        <div v-if="loaded">
          <span class="italic text-zinc-500">
            {{ bot_setup_incomplete_warning }}
          </span>
          <fieldset>
            <BaseButton
              :disabled="!enabled"
              :label="bot_settings_button_label"
              :class="bot_settings_button_classes"
              @click="save_bot_settings"
            >
              <CheckCircleIcon
                v-if="confirmed"
                :class="bot_settings_button_icon_color"
                class="size-5 font-bold text-green-500 inline-block"
              />
            </BaseButton>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>
