<script setup>
import { computed } from 'vue'
import { tagged_moments_link } from '@/helpers/LinkHelpers'
import { tagLike } from '@/helpers/TagHelpers'
import TagToken from '@/components/TagToken.vue'

defineEmits(['apply-tag', 'remove-tag'])

const props = defineProps({
  tags: {
    type: Array,
    default: () => [],
    validator: (value) => {
      return value.every((item) => tagLike(item))
    },
  },
  loading: {
    type: Boolean,
  },
  mode: {
    required: false,
    default: undefined,
    type: String,
    validator: (value) => {
      return ['removable', 'applyable', 'linkable'].includes(value)
    },
  },
})

const remover = computed(() => {
  return props.mode == 'removable' ? '  ×' : ''
})
</script>
<template>
  <span
    v-if="loading"
    class="dark:text-white"
  > Loading... </span>
  <span v-else-if="props.mode == 'removable'">
    <TagToken
      v-for="(t, j) in tags"
      :key="j + '_' + t.id"
      mode="removable"
      :tag="t"
    >
      <a
        href="#"
        class="whitespace-nowrap"
        @click.prevent="$emit('remove-tag', t)"
      >{{ t.name }} {{ remover }}</a>
    </TagToken>
  </span>
  <span v-else-if="props.mode == 'applyable'">
    <TagToken
      v-for="(t, j) in tags"
      :key="j + '_' + t.id"
      :tag="t"
    >
      <a
        href="#"
        class="whitespace-nowrap"
        @click.prevent="$emit('apply-tag', t)"
      >{{ t.name }}</a>
    </TagToken>
  </span>
  <span v-else>
    <TagToken
      v-for="(t, j) in tags"
      :key="j + '_' + t.id"
      :tag="t"
    >
      <a
        :href="tagged_moments_link(t.id)"
        class="whitespace-nowrap"
      >
        {{ t.name }}
      </a>
    </TagToken>
  </span>
</template>
