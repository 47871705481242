<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import ApiService from '@/api'
import BaseButton from '@/components/BaseButton.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import MomentCard from '@/components/MomentCard.vue'

const api = ApiService()
const route = useRoute()

const moment = ref(null)
const loaded = ref(false)

async function loadMoment() {
  loaded.value = false
  const response = await api.getMoment(route.params.id)
  if (!response.ok) {
    loaded.value = 'error'
    return
  }
  if (!response.data) {
    loaded.value = 'not_found'
    return
  }
  moment.value = response.data
  loaded.value = true
}

onMounted(() => {
  loadMoment()
})
</script>

<template>
  <div>
    <HeadlineText headline="Moment Details" />
    <MomentCard
      v-if="loaded === true"
      :moment="moment"
      mode="show_details"
    />
    <div v-else-if="loaded === 'not_found'" class="text-center my-8">
      <img
        src="/404.webp"
        alt="404 not found - This moment does not exist."
        class="mx-auto"
      >
    </div>
    <div v-else-if="loaded === 'error'" class="text-center my-8">
      <p class="text-red-500 mb-4">Failed to load moment details</p>
      <BaseButton
        label="Retry"
        @click="loadMoment"
      />
    </div>
    <div v-else class="text-center my-8">
      Loading...
    </div>
  </div>
</template>
