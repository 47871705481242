<script setup>
import { computed, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useToasterStore } from '@/stores/ToasterStore'
const toaster = useToasterStore()

import { InformationCircleIcon, MapPinIcon } from '@heroicons/vue/24/outline'

import ApiService from '@/api'
import BaseButton from '@/components/BaseButton.vue'
import HeadlineText from '@/components/HeadlineText.vue'
import InputFieldset from '@/components/InputFieldset.vue'
import ToggleCheckbox from '@/components/ToggleCheckbox.vue'

const route = useRoute()
const router = useRouter()
const api = ApiService()

// REACTIVES
const id = ref(undefined)
const is_default = ref(false)
const default_id = ref(undefined)

const form_data = reactive({
  name: {
    untouched: true,
    value: '',
  },
  description: {
    untouched: true,
    value: '',
  },
})

const validate_form = function () {
  let errors = 0

  if (form_data.name.untouched) form_data.name.untouched = false

  if (validate_name.value) errors += 1

  if (form_data.description.untouched) form_data.description.untouched = false

  if (validate_description.value) errors += 1

  if (errors) {
    return false
  } else {
    let place = {}
    if (id.value) place.id = id.value
    place.name = form_data.name.value
    place.description = form_data.description.value

    dispatchRequest(place).then((updatePlaceResponse) => {
      if (!updatePlaceResponse.ok) return

      toaster.notify({
        id: Math.random(),
        message: `Place ${place.name} saved successfully`,
      })

      router.push({
        name: 'places#index',
      })
    })
  }
}

async function dispatchRequest(place) {
  try {
    if (id.value) return await api.updatePlace(place)
    else return await api.createPlace(place, is_default.value)
  } catch (err) {
    console.error('Failed to save place:', err.message)
    return null
  }
}

function touch_and_validate_name() {
  form_data.name.untouched = false
  validate_name.value
}

/* VALIDATOR COMPUTED PROPS */
const validate_name = computed(() => {
  if (form_data.name.untouched) return ''
  return form_data.name.value.length >= 2 ? '' : 'Place name should be at least 2 chars'
})

const validate_description = computed(() => {
  if (form_data.description.untouched) return ''
  return form_data.description.value.length < 128
    ? ''
    : 'Description should be at fewer than 256 characters'
})

function get_default() {
  api.getUser().then((userResponse) => {
    if (!userResponse.ok) return
    default_id.value = userResponse.data.user.default_place_id
    is_default.value = default_id.value == id.value
  })
}

if (route.params.id) {
  console.debug(`Found an id param, get Place #${route.params.id} from API`)

  api.getPlace(route.params.id).then((response) => {
    if (!response.ok) return

    id.value = response.data.id
    form_data.name.value = response.data.name
    form_data.description.value = response.data.description
    console.debug(`loaded Place #${id.value}:${form_data.name.value}`)

    get_default()
  })
}

const update_default_id = function () {
  if (id.value && default_id.value == id.value) {
    // record IS currently the default and the user toggled it OFF, so clear it
    default_id.value = undefined
    api.updateUser({ default_place_id: '' })
  } else {
    // if we're editing an existing record
    if (id.value) {
      // record was NOT previously the default. the user toggled it ON, so set it as the default
      api.updateUser({ default_place_id: id.value }).then((userResponse) => {
        if (!userResponse.ok) return
        default_id.value = id.value
      })
    } else {
      // creating a NEW record which hasn't yet been assigned an ID.
      // we include an additional flag in the eventual request
      // indicating API that the new record should be the default
      is_default.value = true
    }
  }
}
</script>

<template>
  <div>
    <HeadlineText headline="Place" />

    <form method="post" action="/places" @submit.prevent.once="validate_form">
      <input type="hidden" name="id" :value="id" />

      <InputFieldset
        v-model="form_data.name.value"
        :has-errors="validate_name"
        input-type="text"
        label="Name"
        @blur="touch_and_validate_name"
      >
        <MapPinIcon class="size-4 text-gray inline mb-1" />
      </InputFieldset>

      <InputFieldset
        v-model="form_data.description.value"
        :has-errors="validate_description"
        label="Description"
      >
        <InformationCircleIcon class="size-4 text-gray inline mb-1" />
      </InputFieldset>

      <ToggleCheckbox
        v-model="is_default"
        label="Default Place when creating new Moments"
        @toggle="update_default_id"
      />

      <fieldset class="flex">
        <BaseButton :align-right="true" label="Save" type="submit" />
      </fieldset>
    </form>
  </div>
</template>
