<script setup>
import { computed } from 'vue'
const props = defineProps({
  selected: {
    type: Boolean,
    default: false,
  },
})

const selectedClass = computed(() => {
  return props.selected
    ? 'bg-primary-100/20 dark:bg-primary-700/10 outline-primary-500'
    : 'outline-gray-500/30'
})
</script>

<template>
  <div
    :class="selectedClass"
    class="rounded-lg p-2 h-32 mx-3 size-14 grow outline outline-2"
  >
    <slot />
  </div>
</template>
